export default {
	INITIALIZE_DISEASES: "static_list/INITIALIZE_DISEASES",
	INITIALIZE_UMLS: "static_list/INITIALIZE_UMLS",
	INITIALIZE_GENETIC_TESTINGS: "static_list/INITIALIZE_GENETIC_TESTINGS",
	INITIALIZE_GENETIC_TESTINGS_UMLS: "static_list/INITIALIZE_GENETIC_TESTINGS_UMLS",
	INITIALIZE_ANCESTRY_LIST: "static_list/INITIALIZE_ANCESTRY_LIST",
	INITIALIZE_SKIP_LOGICS: "static_list/INITIALIZE_SKIP_LOGICS",
	INITIALIZE_PATIENT_STATUS: "static_list/INITIALIZE_PATIENT_STATUS",
	INITIALIZE_SURVEYS: "static_list/INITIALIZE_SURVEYS",
	INITIALIZE_DISEASE_CATEGORIES: "static_list/INITIALIZE_DISEASE_CATEGORIES",
	INITIALIZE_GENETIC_TESTING_LABS: "static_list/INITIALIZE_GENETIC_TESTING_LABS",
	INITIALIZE_GENETIC_TESTING_PANELS: "static_list/INITIALIZE_GENETIC_TESTING_PANELS"

}
