import sdk from './sdk';

const get_disease = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/disease/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_umls_diseases = async (clinician_id) => {

  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_disease/get_all_umls_diseases/`;
    let response = await sdk.request('get', url, {clinician_id: clinician_id});

    let data = response.data;
    return data;

  }catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_all_skip_logic = async () =>{
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_disease/get_all_skip_logic/`;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  }catch (err) {
    throw sdk.parse_error_message(err)
  }
}
// const post_member_disease = async (payload) => {
//   try {
//
//     let url = process.env.REACT_APP_ROOT_API_URL + '/member_disease/';
//     let response = await sdk.request('post', url, payload);
//
//     let data = response.data;
//     return data;
//
//   } catch (err) {
//     throw sdk.parse_error_message(err)
//   }
// }

const patch_member_disease_id = async (id, payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_disease/${id}/`;
    let response = await sdk.request('patch', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_member_disease_id = async (id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_disease/${id}/`;
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

// const post_member_skip_logic = async (payload) => {
//   try {
//
//     let url = process.env.REACT_APP_ROOT_API_URL + '/member_skip_logic/';
//     let response = await sdk.request('post', url, payload);
//
//     let data = response.data;
//     return data;
//
//   } catch (err) {
//     throw sdk.parse_error_message(err)
//   }
// }

const delete_remove_disease_skip_logic = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/members/remove_disease_skip_logic/`;
    let response = await sdk.request('delete', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_member_id_diseases = async (id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/members/${id}/diseases/`;
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const check_disease_is_subconcept = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_disease/subconcept_check/`;
    let response = await sdk.request('post', url, payload)

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const check_members_disease_cubconcept = async (payload) =>{
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + `/member_disease/member_disease_subconcept_check/`;
    let response = await sdk.request('post', url, payload)

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const save_disease = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/disease/save_disease/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const upload_disease_file = async (payload) => {

  try{
    let url = process.env.REACT_APP_ROOT_API_URL + '/disease/upload_custom_disease_list/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;
  }catch(err){
    throw sdk.parse_error_message(err)
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  get_disease,
  // post_member_disease,
  // post_member_skip_logic,
  get_member_id_diseases,
  patch_member_disease_id,
  delete_member_disease_id,
  delete_remove_disease_skip_logic,
  get_umls_diseases,
  check_disease_is_subconcept,
  save_disease,
  check_members_disease_cubconcept,
  get_all_skip_logic,
  upload_disease_file
}
