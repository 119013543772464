//@ts-ignore
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import {cloneDeep, isEmpty} from 'lodash'
import query_types from './query-types'

export function buildQueryPayload(filterType: string, filterSets: any, familyFilters: any, umls_genetic_testings: any, umls_diseases: any, all_diseases: any, disease_categories: any, cache: boolean, trigger_other_standard_filters: boolean, other_standard_data: any){

    let kind = 'proband'

    if(filterType == 'probands'){
        kind = 'proband'
    }
    else if(filterType == 'everyone'){
        kind = 'member'
    }
    else if(filterType == 'families'){
        kind = 'family'
    }

    let top_level_p_member_query_variable_clone = cloneDeep(query_types.top_level_p_member_query_variable)

    let is_same_side_of_the_family_filter = kind == 'family' && familyFilters.relationship == 'same_side_of_the_family' && familyFilters.number_of_people_to_meet_criteria.relationalOperator != 'any' && familyFilters.number_of_people_to_meet_criteria.value

    let final_payload = {
        name: `Query-Payload-${uuidv4()}`,
        kind: kind,
        // query: kind == 'member' ? {} : top_level_p_member_query_variable_clone,
        query: kind == 'member' || kind == 'proband' || is_same_side_of_the_family_filter ? {} : top_level_p_member_query_variable_clone,
        cache: cache,
        async: true
    }

    let set_index = trigger_other_standard_filters ? 1 : 0;
    let family_filter_count_container_exists:boolean = false;
    let other_standard_filter_exists:boolean = false;

    // if other filters only without any filters, assign it to query object
    if(trigger_other_standard_filters && filterSets.length == 0){
        if(other_standard_data){
            let other_filters = cloneDeep(query_types.filter)
            other_filters.operator = 'and';

            if(other_standard_data.search){
                let name_filter = cloneDeep(query_types.member_name_filter);

                let name_filter_array = other_standard_data.search.split(' ');
                let field_name_array = ['first_name', 'last_name'];

                name_filter.expressionList[0].expressionList[0].value = other_standard_data.search;

                if(name_filter_array.length > 1){
                    for(let item1 of name_filter_array){
                        for(let item2 of name_filter_array){
                            let and_filter = cloneDeep(query_types.filter);
                            and_filter.operator = 'and';

                            let in_filter1 = {
                                operator: "in",
                                expression_type: "expression",
                                expressionList: [
                                    {
                                        value: item1,
                                        expression_type: "value"
                                    },
                                    {
                                        variableName: "member",
                                        fieldName: "first_name",
                                        expression_type: "variableField"
                                    }
                                ]
                            }

                            let in_filter2 = {
                                operator: "in",
                                expression_type: "expression",
                                expressionList: [
                                    {
                                        value: item2,
                                        expression_type: "value"
                                    },
                                    {
                                        variableName: "member",
                                        fieldName: "last_name",
                                        expression_type: "variableField"
                                    }
                                ]
                            }
                            and_filter.expressionList.push(in_filter1);
                            and_filter.expressionList.push(in_filter2);
                            name_filter.expressionList.push(and_filter);
                        }
                    }
                }
                else {
                    for (let item of name_filter_array) {
                        for (let field_name of field_name_array) {
                            let in_filter = {
                                operator: "in",
                                expression_type: "expression",
                                expressionList: [
                                    {
                                        value: item,
                                        expression_type: "value"
                                    },
                                    {
                                        variableName: "member",
                                        fieldName: field_name,
                                        expression_type: "variableField"
                                    }
                                ]
                            }
                            name_filter.expressionList.push(in_filter);
                        }
                    }
                }

                other_filters.expressionList.push(name_filter);
            }

            if(other_standard_data.dob.start_date){
                let dob_filter = other_standard_data.dob.sign == 'between' ? cloneDeep(query_types.member_dob_between_filter) : cloneDeep(query_types.member_dob_filter)
                if(other_standard_data.dob.sign == 'between'){
                    dob_filter.expressionList[0].expressionList[1].value = other_standard_data.dob.start_date
                    dob_filter.expressionList[1].expressionList[1].value = other_standard_data.dob.end_date
                }
                else{
                    dob_filter.operator = other_standard_data.dob.sign;
                    dob_filter.expressionList[1].value = other_standard_data.dob.start_date;
                }
                other_filters.expressionList.push(dob_filter);
            }

            if(other_standard_data.age.age){
                let age_filter = other_standard_data.age.sign == 'between' ? cloneDeep(query_types.member_age_between_filter) : cloneDeep(query_types.member_age_filter)
                if(other_standard_data.age.sign == 'between'){
                    age_filter.expressionList[0].expressionList[1].value = other_standard_data.age.age
                    age_filter.expressionList[1].expressionList[1].value = other_standard_data.age.age_to
                }
                else{
                    age_filter.operator = other_standard_data.age.sign;
                    age_filter.expressionList[1].value = other_standard_data.age.age;
                }
                other_filters.expressionList.push(age_filter);
            }

            if(other_standard_data.gender){
                let gender_filter = cloneDeep(query_types.member_gender_filter);
                gender_filter.expressionList[1].value = other_standard_data.gender;
                other_filters.expressionList.push(gender_filter);
            }

            if(other_standard_data.clinician){
                let clinician_filter = cloneDeep(query_types.clinician_filter);
                clinician_filter.expressionList[0].expressionList[1].value = other_standard_data.clinician;
                other_filters.expressionList.push(clinician_filter);
            }

            if(other_standard_data.status){
                // should only be the case of 2 when status is not_blank bc not_blank = [in_progress, completed]
                // or 1 when it is one of in_progress, completed, or no_status
                if (other_standard_data.status.length == 2) {
                    let status_filter1 = cloneDeep(query_types.status_filter);
                    status_filter1.expressionList[1].value = other_standard_data.status[0];
                    other_filters.expressionList.push(status_filter1);
                    let status_filter2 = cloneDeep(query_types.status_filter);
                    status_filter2.expressionList[1].value = other_standard_data.status[1];
                    other_filters.expressionList.push(status_filter2);
                    other_filters.operator = 'or';
                } else {
                    let status_filter = cloneDeep(query_types.status_filter);
                    status_filter.expressionList[1].value = other_standard_data.status;
                    other_filters.expressionList.push(status_filter);
                }
            }

            final_payload.query = other_filters;
            other_standard_filter_exists = true;
        }
    }

    for (let filter_set of filterSets){

        let filterSets_andOr = filterSets[1] ? filterSets[1].andOr : filter_set.andOr
        let filterItems = filter_set.items

        // let nest_count = kind == 'member' ? 0 : 1;
        let nest_count = kind == 'member' || kind == 'proband' || is_same_side_of_the_family_filter ? 0 : 1;
        let filterSetsLength = trigger_other_standard_filters ? filterSets.length + 1 : filterSets.length;

        // if(kind == 'member'){
        if(kind == 'member' || kind == 'proband'){
            let cloned_multiple_filter_set_level = cloneDeep(query_types.multiple_filter_set_level)
            cloned_multiple_filter_set_level.operator = filterSets_andOr
            if (!('expressionList' in final_payload.query)){
                final_payload.query = cloned_multiple_filter_set_level;
            }
            nest_count += 1;
        }
        else{

            if (is_same_side_of_the_family_filter){
                let cloned_same_side_of_the_family_filter = cloneDeep(query_types.same_side_of_the_family_filter)

                let operator = familyFilters.number_of_people_to_meet_criteria.relationalOperator
                let meet_criteria_value = familyFilters.number_of_people_to_meet_criteria.value

                cloned_same_side_of_the_family_filter.expressionList[0].operator = operator
                cloned_same_side_of_the_family_filter.expressionList[0].expressionList[1].value = parseInt(meet_criteria_value, 10)
                cloned_same_side_of_the_family_filter.expressionList[1].operator = operator
                cloned_same_side_of_the_family_filter.expressionList[1].expressionList[1].value = parseInt(meet_criteria_value, 10)
                final_payload.query = cloned_same_side_of_the_family_filter;
            }

            else{
                if(filterSetsLength > 1){
                    let cloned_multiple_filter_set_level = cloneDeep(query_types.multiple_filter_set_level)
                    cloned_multiple_filter_set_level.operator = filterSets_andOr

                    if(final_payload.query.expressionList.length == 0){
                        //@ts-ignore
                        final_payload.query.expressionList.push(cloned_multiple_filter_set_level)
                    }
                    nest_count += 1;
                }
            }
        }


        // let disease_field_type_exists = filterItems.find((item: any) => item.fieldType == 'disease')
        // let genetic_testing_field_type_exists = filterItems.find((item: any) => item.fieldType == 'genetic_testing')
        // let referral_criteria_field_type_exists = filterItems.find((item: any) => item.fieldType == 'referral_criteria')
        // // additional field types checks

        // let types_existing: string[] = []

        // if (disease_field_type_exists){
        //     types_existing.push('disease')
        // }
        // if (genetic_testing_field_type_exists){
        //     types_existing.push('genetic_testing')
        // }
        // if (referral_criteria_field_type_exists){
        //     types_existing.push('referral_criteria')
        // }

        let multiple_filter_item_container_exists:boolean = false;
        let filter_index = 0;
        for (let filter_item of filterItems){
            let reference = final_payload.query.expressionList
            for(let i = 1; i <= nest_count - 1; i++) {
                // @ts-ignore
                if(reference[set_index]){
                    // @ts-ignore
                    reference = reference[set_index].expressionList
                }
                else if (reference[0]){
                    // @ts-ignore
                    reference = reference[0].expressionList
                }
            }


            let paternal_both_reference = null;
            let maternal_both_reference = null;

            if (is_same_side_of_the_family_filter){
                paternal_both_reference = final_payload.query.expressionList[0].expressionList[0].expressionList[0].expressionList[0].expressionList[1].expressionList
                maternal_both_reference = final_payload.query.expressionList[1].expressionList[0].expressionList[0].expressionList[0].expressionList[1].expressionList
            }

            if(kind == 'family' && familyFilters.number_of_people_to_meet_criteria.relationalOperator != 'any' && familyFilters.number_of_people_to_meet_criteria.value && familyFilters.relationship != 'same_side_of_the_family'){

                if(!family_filter_count_container_exists){
                    let cloned_Count_condition_for_family_search = cloneDeep(query_types.Count_condition_for_family_search);
                    cloned_Count_condition_for_family_search.operator = familyFilters.number_of_people_to_meet_criteria.relationalOperator;
                    cloned_Count_condition_for_family_search.expressionList[1].value = parseInt(familyFilters.number_of_people_to_meet_criteria.value, 10);

                    if(filterSetsLength > 1){
                        cloned_Count_condition_for_family_search.expressionList[0].expressionList[0].expressionList = cloneDeep(final_payload.query.expressionList)
                        final_payload.query.expressionList = [cloned_Count_condition_for_family_search];
                    }
                    else{
                        reference.push(cloned_Count_condition_for_family_search);
                    }
                    family_filter_count_container_exists = true;
                }

                if(filterSetsLength > 1){
                    reference = final_payload.query.expressionList[0].expressionList[0].expressionList[0].expressionList[0].expressionList;
                }
                else{
                    reference = reference[0].expressionList[0].expressionList[0].expressionList;
                }
            }

            if(trigger_other_standard_filters){
                if(other_standard_data && !other_standard_filter_exists){
                    let other_filters = cloneDeep(query_types.filter)
                    other_filters.operator = 'and';

                    if(other_standard_data.search){
                        let name_filter = cloneDeep(query_types.member_name_filter);

                        let name_filter_array = other_standard_data.search.split(' ');
                        let field_name_array = ['first_name', 'last_name'];

                        name_filter.expressionList[0].expressionList[0].value = other_standard_data.search;

                        if(name_filter_array.length > 1){
                            for(let item1 of name_filter_array){
                                for(let item2 of name_filter_array){
                                    let and_filter = cloneDeep(query_types.filter);
                                    and_filter.operator = 'and';

                                    let in_filter1 = {
                                        operator: "in",
                                        expression_type: "expression",
                                        expressionList: [
                                            {
                                                value: item1,
                                                expression_type: "value"
                                            },
                                            {
                                                variableName: "member",
                                                fieldName: "first_name",
                                                expression_type: "variableField"
                                            }
                                        ]
                                    }

                                    let in_filter2 = {
                                        operator: "in",
                                        expression_type: "expression",
                                        expressionList: [
                                            {
                                                value: item2,
                                                expression_type: "value"
                                            },
                                            {
                                                variableName: "member",
                                                fieldName: "last_name",
                                                expression_type: "variableField"
                                            }
                                        ]
                                    }
                                    and_filter.expressionList.push(in_filter1);
                                    and_filter.expressionList.push(in_filter2);
                                    name_filter.expressionList.push(and_filter);
                                }
                            }
                        }
                        else {
                            for (let item of name_filter_array) {
                                for (let field_name of field_name_array) {
                                    let in_filter = {
                                        operator: "in",
                                        expression_type: "expression",
                                        expressionList: [
                                            {
                                                value: item,
                                                expression_type: "value"
                                            },
                                            {
                                                variableName: "member",
                                                fieldName: field_name,
                                                expression_type: "variableField"
                                            }
                                        ]
                                    }
                                    name_filter.expressionList.push(in_filter);
                                }
                            }
                        }

                        other_filters.expressionList.push(name_filter);
                    }

                    if(other_standard_data.dob.start_date){
                        let dob_filter = other_standard_data.dob.sign == 'between' ? cloneDeep(query_types.member_dob_between_filter) : cloneDeep(query_types.member_dob_filter)
                        if(other_standard_data.dob.sign == 'between'){
                            dob_filter.expressionList[0].expressionList[1].value = other_standard_data.dob.start_date
                            dob_filter.expressionList[1].expressionList[1].value = other_standard_data.dob.end_date
                        }
                        else{
                            dob_filter.operator = other_standard_data.dob.sign;
                            dob_filter.expressionList[1].value = other_standard_data.dob.start_date;
                        }
                        other_filters.expressionList.push(dob_filter);
                    }

                    if(other_standard_data.age.age){
                        let age_filter = other_standard_data.age.sign == 'between' ? cloneDeep(query_types.member_age_between_filter) : cloneDeep(query_types.member_age_filter)
                        if(other_standard_data.age.sign == 'between'){
                            age_filter.expressionList[0].expressionList[1].value = other_standard_data.age.age
                            age_filter.expressionList[1].expressionList[1].value = other_standard_data.age.age_to
                        }
                        else{
                            age_filter.operator = other_standard_data.age.sign;
                            age_filter.expressionList[1].value = other_standard_data.age.age;
                        }
                        other_filters.expressionList.push(age_filter);
                    }

                    if(other_standard_data.gender){
                        let gender_filter = cloneDeep(query_types.member_gender_filter);
                        gender_filter.expressionList[1].value = other_standard_data.gender;
                        other_filters.expressionList.push(gender_filter);
                    }

                    if(other_standard_data.clinician){
                        let clinician_filter = cloneDeep(query_types.clinician_filter);
                        clinician_filter.expressionList[0].expressionList[1].value = other_standard_data.clinician;
                        other_filters.expressionList.push(clinician_filter);
                    }

                    if(other_standard_data.status){
                        // should only be the case of 2 when status is not_blank bc not_blank = [in_progress, completed]
                        // or 1 when it is one of in_progress, completed, or no_status
                        if (other_standard_data.status.length == 2) {
                            let status_filter1 = cloneDeep(query_types.status_filter);
                            status_filter1.expressionList[1].value = other_standard_data.status[0];
                            other_filters.expressionList.push(status_filter1);
                            let status_filter2 = cloneDeep(query_types.status_filter);
                            status_filter2.expressionList[1].value = other_standard_data.status[1];
                            other_filters.expressionList.push(status_filter2);
                            other_filters.operator = 'or';
                        } else {
                            let status_filter = cloneDeep(query_types.status_filter);
                            status_filter.expressionList[1].value = other_standard_data.status;
                            other_filters.expressionList.push(status_filter);
                        }
                    }

                    reference.push(other_filters);
                    other_standard_filter_exists = true;
                }
            }

            // let filterItemsLength = other_standard_filter_exists ? filterItems.length + 1 : filterItems.length
            let filterItemsLength = filterItems.length


            //need another condition if there are more than one filter items on a filter set, then we will need a new nest, to combine the filter items
            if(filterItemsLength > 1){

                if(!multiple_filter_item_container_exists){
                    let cloned_multiple_filter_set_level = cloneDeep(query_types.multiple_filter_set_level)
                    cloned_multiple_filter_set_level.operator = filterItems[1] ? filterItems[1].andOr : filter_item.andOr

                    if(is_same_side_of_the_family_filter){
                        let cloned_multiple_filter_set_level1 = cloneDeep(query_types.multiple_filter_set_level)
                        cloned_multiple_filter_set_level1.operator = filterItems[1] ? filterItems[1].andOr : filter_item.andOr
                        let cloned_multiple_filter_set_level2 = cloneDeep(query_types.multiple_filter_set_level)
                        cloned_multiple_filter_set_level2.operator = filterItems[1] ? filterItems[1].andOr : filter_item.andOr
                        paternal_both_reference.push(cloned_multiple_filter_set_level1)
                        maternal_both_reference.push(cloned_multiple_filter_set_level2)
                    }
                    else{
                        //@ts-ignore
                        reference.push(cloned_multiple_filter_set_level)
                    }
                }

                if(is_same_side_of_the_family_filter){

                    if(paternal_both_reference[set_index]){
                        // @ts-ignore
                        paternal_both_reference = paternal_both_reference[set_index].expressionList
                    }
                    else{
                        // @ts-ignore
                        paternal_both_reference = paternal_both_reference[0].expressionList
                    }

                    if(maternal_both_reference[set_index]){
                        // @ts-ignore
                        maternal_both_reference = maternal_both_reference[set_index].expressionList
                    }
                    else{
                        // @ts-ignore
                        maternal_both_reference = maternal_both_reference[0].expressionList
                    }

                }
                else{

                    if(reference[set_index]){
                        // @ts-ignore
                        reference = reference[set_index].expressionList
                    }
                    else{
                        // @ts-ignore
                        reference = reference[0].expressionList
                    }

                }

                multiple_filter_item_container_exists = true;
            }


            // setting of qualifiers (exists) for each filter item
            if(filter_item.fieldType == "disease"){
                if(filter_item.cancerType != 'no_disease' && filter_item.cancerType != 'no_UMLS' && filter_item.cancerType != 'no_blood' && filter_item.cancerType != 'no_cancer-polyps' && filter_item.cancerType != 'no_heart' && filter_item.cancerType != 'no_mental-behavioral-neurological' && filter_item.cancerType != 'no_other_conditions' && !filter_item.cancerType.startsWith('no_')){
                    let p_member_disease_qualifier_clone = cloneDeep(query_types.p_member_disease_qualifier)
                    if(is_same_side_of_the_family_filter){
                        let p_member_disease_qualifier_clone1 = cloneDeep(query_types.p_member_disease_qualifier)
                        p_member_disease_qualifier_clone1.inputSet.variableName = 'x'
                        let p_member_disease_qualifier_clone2 = cloneDeep(query_types.p_member_disease_qualifier)
                        p_member_disease_qualifier_clone2.inputSet.variableName = 'y'

                        paternal_both_reference.push(p_member_disease_qualifier_clone1)
                        maternal_both_reference.push(p_member_disease_qualifier_clone2)
                    }
                    else{
                        reference.push(p_member_disease_qualifier_clone)
                    }
                    let set_or_item = filterItems.length > 1 ? 'item' : 'set'
                    let index = set_or_item == 'set' ? set_index : filter_index

                    if(is_same_side_of_the_family_filter){
                        if(paternal_both_reference[index]){
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[0].expressionList
                        }

                        if(maternal_both_reference[index]){
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[0].expressionList
                        }
                    }
                    else{
                        if(reference[index]){
                            // @ts-ignore
                            reference = reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            reference = reference[0].expressionList
                        }
                    }
                }
            }
            else if(filter_item.fieldType == "genetic_testing"){
                if(filter_item.gene != 'not-tested'){
                    let p_member_gene_qualifier_clone = cloneDeep(query_types.p_member_gene_qualifier)
                    if(is_same_side_of_the_family_filter){
                        let p_member_gene_qualifier_clone1 = cloneDeep(query_types.p_member_gene_qualifier)
                        p_member_gene_qualifier_clone1.inputSet.variableName = 'x'
                        let p_member_gene_qualifier_clone2 = cloneDeep(query_types.p_member_gene_qualifier)
                        p_member_gene_qualifier_clone2.inputSet.variableName = 'y'

                        paternal_both_reference.push(p_member_gene_qualifier_clone1)
                        maternal_both_reference.push(p_member_gene_qualifier_clone2)
                    }
                    else{
                        reference.push(p_member_gene_qualifier_clone)
                    }
                    let set_or_item = filterItems.length > 1 ? 'item' : 'set'
                    let index = set_or_item == 'set' ? set_index : filter_index

                    if(is_same_side_of_the_family_filter){
                        if(paternal_both_reference[index]){
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[0].expressionList
                        }

                        if(maternal_both_reference[index]){
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[0].expressionList
                        }
                    }
                    else{
                        if(reference[index]){
                            // @ts-ignore
                            reference = reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            reference = reference[0].expressionList
                        }
                    }
                }
            }
            else if((filter_item.fieldType == "referral_criteria")){
                if(filter_item.referral != 'none'){
                    let p_member_referral_qualifier_clone = cloneDeep(query_types.p_member_referral_qualifier)
                    if(is_same_side_of_the_family_filter){
                        let p_member_referral_qualifier_clone1 = cloneDeep(query_types.p_member_referral_qualifier)
                        p_member_referral_qualifier_clone1.inputSet.variableName = 'x'
                        let p_member_referral_qualifier_clone2 = cloneDeep(query_types.p_member_referral_qualifier)
                        p_member_referral_qualifier_clone2.inputSet.variableName = 'y'

                        paternal_both_reference.push(p_member_referral_qualifier_clone1)
                        maternal_both_reference.push(p_member_referral_qualifier_clone2)
                    }
                    else{
                        reference.push(p_member_referral_qualifier_clone)
                    }
                    let set_or_item = filterItems.length > 1 ? 'item' : 'set'
                    let index = set_or_item == 'set' ? set_index : filter_index

                    if(is_same_side_of_the_family_filter){
                        if(paternal_both_reference[index]){
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            paternal_both_reference = paternal_both_reference[0].expressionList
                        }

                        if(maternal_both_reference[index]){
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            maternal_both_reference = maternal_both_reference[0].expressionList
                        }
                    }
                    else{
                        if(reference[index]){
                            // @ts-ignore
                            reference = reference[index].expressionList
                        }
                        else{
                            // @ts-ignore
                            reference = reference[0].expressionList
                        }
                    }
                }
            }
            // else if((filter_item.fieldType == "survey")){
            //     let survey_qualifier_clone = cloneDeep(query_types.survey_qualifier)
            //     if(is_same_side_of_the_family_filter){
            //         let survey_qualifier_clone1 = cloneDeep(query_types.survey_qualifier)
            //         survey_qualifier_clone1.inputSet.variableName = 'x'
            //         let survey_qualifier_clone2 = cloneDeep(query_types.survey_qualifier)
            //         survey_qualifier_clone2.inputSet.variableName = 'y'

            //         paternal_both_reference.push(survey_qualifier_clone1)
            //         maternal_both_reference.push(survey_qualifier_clone2)
            //     }
            //     else{
            //         reference.push(survey_qualifier_clone)
            //     }
            //     let set_or_item = filterItems.length > 1 ? 'item' : 'set'
            //     let index = set_or_item == 'set' ? set_index : filter_index

            //     if(is_same_side_of_the_family_filter){
            //         if(paternal_both_reference[index]){
            //             // @ts-ignore
            //             paternal_both_reference = paternal_both_reference[index].expressionList
            //         }
            //         else{
            //             // @ts-ignore
            //             paternal_both_reference = paternal_both_reference[0].expressionList
            //         }

            //         if(maternal_both_reference[index]){
            //             // @ts-ignore
            //             maternal_both_reference = maternal_both_reference[index].expressionList
            //         }
            //         else{
            //             // @ts-ignore
            //             maternal_both_reference = maternal_both_reference[0].expressionList
            //         }
            //     }
            //     else{
            //         if(reference[index]){
            //             // @ts-ignore
            //             reference = reference[index].expressionList
            //         }
            //         else{
            //             // @ts-ignore
            //             reference = reference[0].expressionList
            //         }
            //     }
            // }


            // actual filters
            if(filter_item.fieldType == "disease"){
                let disease_filter_clone = cloneDeep(query_types.filter)
                let disease_filter_item_clone = cloneDeep(query_types.disease_filter_item)

                let disease_info = all_diseases.find((disease: any) => (disease.disease_code && disease.disease_code == filter_item.cancerType) || (disease.umls_id && disease.umls_id == filter_item.cancerType))

                let is_umls = umls_diseases.find((disease: any) => disease.umls_id && disease.umls_id == filter_item.cancerType)

                if (filter_item.cancerType == 'any_disease' || filter_item.cancerType == 'no_disease' || filter_item.cancerType == 'any_UMLS' || filter_item.cancerType == 'no_UMLS' || filter_item.cancerType == 'any_blood' || filter_item.cancerType == 'no_blood' || filter_item.cancerType == 'any_cancer-polyps' || filter_item.cancerType == 'no_cancer-polyps' || filter_item.cancerType == 'any_heart' || filter_item.cancerType == 'no_heart' || filter_item.cancerType == 'any_mental-behavioral-neurological' || filter_item.cancerType == 'no_mental-behavioral-neurological' || filter_item.cancerType.startsWith('any_') || filter_item.cancerType.startsWith('no_')){
                    disease_filter_item_clone[0].expressionList[1].value = filter_item.cancerType //filter_item.cancerType
                    disease_filter_item_clone[0].expressionList[0].fieldName = is_umls ? 'umls_id' : 'code'
                }
                else{
                    let subconcept_disease_filter = cloneDeep(query_types.Subconcept_or_code_operator)
                    subconcept_disease_filter.expressionList[0].expressionList[0].variableName = 'd'
                    subconcept_disease_filter.expressionList[1].expressionList[0].variableName = 'd'

                    subconcept_disease_filter.expressionList[0].expressionList[1].value = is_umls ? filter_item.cancerType : disease_info.disease_code
                    subconcept_disease_filter.expressionList[1].expressionList[1].value = is_umls ? filter_item.cancerType : disease_info.umls_id

                    if(is_umls){
                        subconcept_disease_filter.expressionList.splice(0, 1);
                    }

                    disease_filter_item_clone[0] = subconcept_disease_filter
                }

                disease_filter_item_clone[1].operator = filter_item.relationalOperator
                disease_filter_item_clone[1].expressionList[1].value = parseInt(filter_item.age, 10)

                if (filter_item.relationalOperator == 'any_age' || filter_item.cancerType == 'no_disease' || filter_item.cancerType == 'no_UMLS' || filter_item.cancerType == 'no_blood' || filter_item.cancerType == 'no_cancer-polyps' || filter_item.cancerType == 'no_heart' || filter_item.cancerType == 'no_mental-behavioral-neurological' || filter_item.cancerType == 'no_other_conditions' || filter_item.cancerType.startsWith('no_')){
                    disease_filter_item_clone.splice(1, 1);
                }

                if(filter_item.cancerType == 'any_disease' || filter_item.cancerType == 'no_disease'){
                    let Count_condition_disease = cloneDeep(query_types.Count_condition)
                    Count_condition_disease.operator = filter_item.cancerType == 'any_disease' ? '>' : '='
                    Count_condition_disease.expressionList[0].expressionList[0].fieldName = 'diseases'
                    Count_condition_disease.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_disease
                }
                else if (filter_item.cancerType == 'any_UMLS' || filter_item.cancerType == 'no_UMLS'){
                    let Count_condition_umls_clone = cloneDeep(query_types.Count_condition_with_filter)
                    Count_condition_umls_clone.operator = filter_item.cancerType == 'any_UMLS' ? '>' : '='
                    // Count_condition_umls_clone.expressionList[0].expressionList[0].fieldName = 'diseases'
                    Count_condition_umls_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_umls_clone
                }
                else if (filter_item.cancerType == 'any_blood' || filter_item.cancerType == 'no_blood'){

                    // // Use Subconcept operator, should be a different filter format
                    // let Count_condition_blood_clone = cloneDeep(Count_condition_subconcept)
                    // Count_condition_blood_clone.operator = filter_item.cancerType == 'anybl_ood' ? '>' : '='
                    // Count_condition_blood_clone.expressionList[0].expressionList[0].expressionList[1].value = 'C0018939'
                    // Count_condition_blood_clone.expressionList[1].value = 0
                    // disease_filter_item_clone[0] = Count_condition_blood_clone

                    let Count_condition_blood_clone = cloneDeep(query_types.Count_condition_or_subconcept)
                    Count_condition_blood_clone.operator = filter_item.cancerType == 'any_blood' ? '>' : '='

                    let blood_parent_concept = 'C0018939'

                    let standard_blood_subconcepts = [
                        {code: 'D046', umls_id: 'C0684275'},
                        {code: 'D045', umls_id: 'C0020445'},
                        {code: 'D044', umls_id: 'C0149871'},
                        {code: 'D047', umls_id: 'C0020538'},
                        {code: 'D048', umls_id: 'C0020443'},
                        {code: 'D049', umls_id: 'C0034065'},
                        {code: 'D050', umls_id: 'C0002895'},
                        {code: 'D051', umls_id: 'C0039730'},
                        {code: 'D052', umls_id: 'C0042974'}
                    ]

                    let subconcept = cloneDeep(query_types.Subconcept_operator)
                    subconcept.expressionList[1].value = blood_parent_concept
                    Count_condition_blood_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)

                    for(let item of standard_blood_subconcepts){
                        let subconcept = cloneDeep(query_types.Subconcept_or_code_operator)
                        subconcept.expressionList[0].expressionList[1].value = item.code
                        subconcept.expressionList[1].expressionList[1].value = item.umls_id
                        Count_condition_blood_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }

                    Count_condition_blood_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_blood_clone
                }

                else if (filter_item.cancerType == 'any_cancer-polyps' || filter_item.cancerType == 'no_cancer-polyps'){
                    // Use Subconcept operator, should be a different filter format
                    let Count_condition_cancer_polyps_clone = cloneDeep(query_types.Count_condition_or_subconcept)
                    Count_condition_cancer_polyps_clone.operator = filter_item.cancerType == 'any_cancer-polyps' ? '>' : '='

                    let cancer_polyps_parent_concept = 'C0006826'

                    let standard_cancer_polyps_subconcepts = [
                        {code: 'D001', umls_id: 'C0153633'},
                        {code: 'D002', umls_id: 'C0006142'},
                        {code: 'D003', umls_id: 'C0346629'},
                        {code: 'D004', umls_id: 'C0949059'},
                        {code: 'D005', umls_id: 'C0007124'},
                        {code: 'D006', umls_id: 'C0740457'},
                        {code: 'D007', umls_id: 'C0023418'},
                        {code: 'D008', umls_id: 'C0025202'},
                        {code: 'D009', umls_id: 'C1140680'},
                        {code: 'D010', umls_id: 'C0346647'},
                        {code: 'D010', umls_id: 'C0235974'},
                        {code: 'D011', umls_id: 'C0376358'},
                        {code: 'D012', umls_id: 'C1261473'},
                        {code: 'D013', umls_id: 'C0153425'},
                        {code: 'D014', umls_id: 'C0024623'},
                        {code: 'D015', umls_id: 'C0007115'},
                        {code: 'D016', umls_id: 'C0947935'},
                        {code: 'D016', umls_id: 'C1519840'},
                        {code: 'D017', umls_id: 'C0153567'},
                        {code: 'D018', umls_id: 'C0750887'},
                        {code: 'D019', umls_id: 'C0206686'},
                        {code: 'D020', umls_id: 'C4721806'},
                        {code: 'D021', umls_id: 'C0750952'},
                        {code: 'D022', umls_id: 'C4013426'},
                        {code: 'D023', umls_id: 'C0431109'},
                        {code: 'D024', umls_id: 'C0079218'},
                        {code: 'D025', umls_id: 'C2348239'},
                        {code: 'D026', umls_id: 'C0153579'},
                        {code: 'D027', umls_id: 'C0017150'},
                        {code: 'D028', umls_id: 'C0206734'},
                        {code: 'D029', umls_id: 'C0206624'},
                        {code: 'D030', umls_id: 'C0019829'},
                        {code: 'D031', umls_id: 'C0022572'},
                        {code: 'D032', umls_id: 'C0391826'},
                        {code: 'D033', umls_id: 'C0242363'},
                        {code: 'D034', umls_id: 'C0030421'},
                        {code: 'D034', umls_id: 'C0031511'},
                        {code: 'D035', umls_id: 'C0262587'},
                        {code: 'D036', umls_id: 'C0153467'},
                        {code: 'D037', umls_id: 'C0032000'},
                        {code: 'D038', umls_id: 'C0035335'},
                        {code: 'D039', umls_id: 'C0206743'},
                        {code: 'D040', umls_id: 'C4055470'},
                        {code: 'D041', umls_id: 'C1368816'},
                        {code: 'D041', umls_id: 'C0206684'},
                        {code: 'D042', umls_id: 'C0349671'},
                        {code: 'D043', umls_id: 'C1336746'},
                    ]

                    let subconcept = cloneDeep(query_types.Subconcept_operator)
                    subconcept.expressionList[1].value = cancer_polyps_parent_concept
                    Count_condition_cancer_polyps_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)

                    for(let item of standard_cancer_polyps_subconcepts){
                        let subconcept = cloneDeep(query_types.Subconcept_or_code_operator)
                        subconcept.expressionList[0].expressionList[1].value = item.code
                        subconcept.expressionList[1].expressionList[1].value = item.umls_id
                        Count_condition_cancer_polyps_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }


                    // Count_condition_cancer_polyps_clone.expressionList[0].expressionList[0].expressionList[0].expressionList[1].value = 'C0006826'
                    Count_condition_cancer_polyps_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_cancer_polyps_clone
                }
                else if (filter_item.cancerType == 'any_heart' || filter_item.cancerType == 'no_heart'){
                    let Count_condition_heart_clone = cloneDeep(query_types.Count_condition_or_subconcept)
                    Count_condition_heart_clone.operator = filter_item.cancerType == 'any_heart' ? '>' : '='

                    let heart_parent_concept = 'C0947842'

                    let standard_heart_subconcepts = [
                        {code: 'D053', umls_id: 'C0003811'},
                        {code: 'D054', umls_id: 'C0349788'},
                        {code: 'D055', umls_id: 'C0004238'},
                        {code: 'D056', umls_id: 'C1142166'},
                        {code: 'D057', umls_id: 'C0878544'},
                        {code: 'D058', umls_id: 'C1631597'},
                        {code: 'D059', umls_id: 'C0007193'},
                        {code: 'D060', umls_id: 'C0027051'},
                        {code: 'D061', umls_id: 'C0018799'},
                        {code: 'D062', umls_id: 'C0007194'},
                        {code: 'D063', umls_id: 'C0023976'}
                    ]

                    let subconcept = cloneDeep(query_types.Subconcept_operator)
                    subconcept.expressionList[1].value = heart_parent_concept
                    Count_condition_heart_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)

                    for(let item of standard_heart_subconcepts){
                        let subconcept = cloneDeep(query_types.Subconcept_or_code_operator)
                        subconcept.expressionList[0].expressionList[1].value = item.code
                        subconcept.expressionList[1].expressionList[1].value = item.umls_id
                        Count_condition_heart_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }

                    // Count_condition_heart_clone.expressionList[0].expressionList[0].expressionList[0].expressionList[1].value = 'C0947842'
                    Count_condition_heart_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_heart_clone
                }
                else if (filter_item.cancerType == 'any_mental-behavioral-neurological' || filter_item.cancerType == 'no_mental-behavioral-neurological'){
                    let Count_condition_any_mental_behavioral_neurological_clone = cloneDeep(query_types.Count_condition_or_subconcept)
                    Count_condition_any_mental_behavioral_neurological_clone.operator = filter_item.cancerType == 'any_mental-behavioral-neurological' ? '>' : '='

                    let list_of_any_mental_behavioral_neurological_parent_concepts = ['C0027765', 'C0205494']

                    let standard_mental_behavioral_neurological_subconcepts = [
                        {code: 'D064', umls_id: 'C0002395'},
                        {code: 'D065', umls_id: 'C0041671'},
                        {code: 'D066', umls_id: 'C1263846'},
                        {code: 'D067', umls_id: 'C1510586'},
                        {code: 'D068', umls_id: 'C0497327'},
                        {code: 'D069', umls_id: 'C4022738'},
                        {code: 'D070', umls_id: 'C0014544'},
                        {code: 'D071', umls_id: 'C0020179'},
                        {code: 'D072', umls_id: 'C3714756'},
                        {code: 'D073', umls_id: 'C0851265'},
                        {code: 'D074', umls_id: 'C0004936'},
                        {code: 'D075', umls_id: 'C0030567'},
                        {code: 'D076', umls_id: 'C0036572'},
                        {code: 'D077', umls_id: 'C0026847'}
                    ]

                    for(let item of list_of_any_mental_behavioral_neurological_parent_concepts){
                        let subconcept = cloneDeep(query_types.Subconcept_operator)
                        subconcept.expressionList[1].value = item
                        Count_condition_any_mental_behavioral_neurological_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }

                    for(let item of standard_mental_behavioral_neurological_subconcepts){
                        let subconcept = cloneDeep(query_types.Subconcept_or_code_operator)
                        subconcept.expressionList[0].expressionList[1].value = item.code
                        subconcept.expressionList[1].expressionList[1].value = item.umls_id
                        Count_condition_any_mental_behavioral_neurological_clone.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }

                    Count_condition_any_mental_behavioral_neurological_clone.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_any_mental_behavioral_neurological_clone
                }
                //for custom disease categories
                else if(filter_item.cancerType.startsWith('any_') || filter_item.cancerType.startsWith('no_')){
                    let Count_condition_any_or_no = cloneDeep(query_types.Count_condition_or_subconcept)
                    Count_condition_any_or_no.operator = filter_item.cancerType.startsWith('any_') ? '>' : '='

                    let disease_category_id = filter_item.cancerType.split('_')
                    disease_category_id = disease_category_id[1]
                    let disease_category = disease_categories.find((cat:any) => cat.disease_category == disease_category_id)
                    let disease_category_umls_ids = [];

                    if(disease_category.umls_id){
                        disease_category_umls_ids = disease_category.umls_id.split(',')
                    }
                    else{
                        if(disease_category_id == 'Other'){
                            let other_category_umls_ids = all_diseases.filter((disease:any) => disease.disease_type == disease_category_id).map((disease:any) => disease.umls_id)
                            disease_category_umls_ids = other_category_umls_ids
                        }
                    }

                    for(let item of disease_category_umls_ids){
                        let subconcept = cloneDeep(query_types.Subconcept_operator)
                        subconcept.expressionList[1].value = item
                        Count_condition_any_or_no.expressionList[0].expressionList[0].expressionList[0].expressionList.push(subconcept)
                    }

                    Count_condition_any_or_no.expressionList[1].value = 0
                    disease_filter_item_clone[0] = Count_condition_any_or_no
                }
                // else if (filter_item.cancerType == 'any_other_conditions' || filter_item.cancerType == 'no_other_conditions'){
                //     let Count_condition_umls_clone = cloneDeep(Count_condition_umls)
                //     Count_condition_umls_clone.operator = filter_item.cancerType == 'any_other_conditions' ? '>' : '='
                //     Count_condition_umls_clone.expressionList[0].expressionList[0].fieldName = 'diseases'
                //     Count_condition_umls_clone.expressionList[1].value = 0
                //     disease_filter_item_clone[0] = Count_condition_umls_clone
                // }
                // this will always be 'and' operator since it always needs to pass both disease name and age diagnosed conditions
                disease_filter_clone.operator = 'and'
                disease_filter_clone.expressionList = disease_filter_item_clone

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let disease_filter_clone1 = cloneDeep(disease_filter_clone)
                    let disease_filter_clone2 = cloneDeep(disease_filter_clone)
                    paternal_both_reference.push(disease_filter_clone1)
                    maternal_both_reference.push(disease_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(disease_filter_clone)
                    reference = reference[0].expressionList
                }

            }
            else if (filter_item.fieldType == "genetic_testing"){
                let gene_filter_clone = cloneDeep(query_types.filter)
                let gene_filter_item_clone = cloneDeep(query_types.gene_filter_item)

                let is_umls = umls_genetic_testings.find((gene: any) => gene.umls_id && gene.umls_id == filter_item.gene)

                gene_filter_item_clone[0].expressionList[0].fieldName = is_umls ? 'umls_id' : 'gene'
                gene_filter_item_clone[0].expressionList[1].value = filter_item.gene // gene or umls id
                gene_filter_item_clone[1].expressionList[1].value = filter_item.result // result
                gene_filter_item_clone[2].expressionList[1].value = filter_item.Variant ? filter_item.Variant : '' // variant

                if (!filter_item.Variant){
                    gene_filter_item_clone.splice(2, 1);
                }

                if (filter_item.result == "any" || !filter_item.result){
                    gene_filter_item_clone.splice(1, 1);
                }

                if (filter_item.gene == 'Any' || filter_item.gene == 'not-tested'){
                    let Count_condition_any_gene = cloneDeep(query_types.Count_condition)
                    Count_condition_any_gene.operator = filter_item.gene == 'Any' ? '>' : '='
                    Count_condition_any_gene.expressionList[0].expressionList[0].fieldName = 'genes'
                    Count_condition_any_gene.expressionList[1].value = 0
                    gene_filter_item_clone[0] = Count_condition_any_gene
                }

                gene_filter_clone.operator = 'and'
                gene_filter_clone.expressionList = gene_filter_item_clone

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let gene_filter_clone1 = cloneDeep(gene_filter_clone)
                    let gene_filter_clone2 = cloneDeep(gene_filter_clone)
                    paternal_both_reference.push(gene_filter_clone1)
                    maternal_both_reference.push(gene_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(gene_filter_clone)
                    reference = reference[0].expressionList
                }
            }
            else if (filter_item.fieldType == "referral_criteria"){
                if(!filter_item.referral){
                    continue;
                }
                let referral_criteria_filter_clone = cloneDeep(query_types.filter)
                let referral_criteria_filter_item_clone = cloneDeep(query_types.referral_criteria_filter_item)

                referral_criteria_filter_item_clone.expressionList[0].value = filter_item.referral;

                if (filter_item.referral == 'any' || filter_item.referral == 'none'){
                    let Count_condition_any_referral = cloneDeep(query_types.Count_condition)
                    Count_condition_any_referral.operator = filter_item.referral == 'any' ? '>' : '='
                    Count_condition_any_referral.expressionList[0].expressionList[0].fieldName = 'referralcriteria'
                    Count_condition_any_referral.expressionList[1].value = 0
                    referral_criteria_filter_item_clone = Count_condition_any_referral
                }

                referral_criteria_filter_clone.operator = 'and'
                referral_criteria_filter_clone.expressionList.push(referral_criteria_filter_item_clone)

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let referral_criteria_filter_clone1 = cloneDeep(referral_criteria_filter_clone)
                    let referral_criteria_filter_clone2 = cloneDeep(referral_criteria_filter_clone)
                    paternal_both_reference.push(referral_criteria_filter_clone1)
                    maternal_both_reference.push(referral_criteria_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(referral_criteria_filter_clone)
                    reference = reference[0].expressionList
                }
            }
            else if (filter_item.fieldType == "survey"){
                let survey_filter_clone = cloneDeep(query_types.filter)
                let survey_filter_item_clone = cloneDeep(query_types.survey_filter_item)

                // survey_filter_item_clone[0].expressionList[1].value = filter_item.surveyName;
                survey_filter_item_clone[0].expressionList[0].expressionList[0].expressionList[1].value = filter_item.questionUUID;

                if(filter_item.questionType == 'select_one' || filter_item.questionType == 'select_all_that_apply' || filter_item.questionType == 'long_list_search'){
                    let survey_filter_item_input_1 = cloneDeep(query_types.survey_filter_item_input);
                    survey_filter_item_input_1.expressionList[0].fieldName = 'master_question_choice_id';
                    survey_filter_item_input_1.expressionList[1].value = `${filter_item.answer}`;
                    survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(survey_filter_item_input_1);
                }
                else if(filter_item.questionType == 'integer' || filter_item.questionType == 'float'){
                    if(filter_item.answer == 'between'){
                        let between_container = cloneDeep(query_types.filter);
                        between_container.operator = 'and';

                        let min_filter = cloneDeep(query_types.survey_filter_item_input);
                        min_filter.operator = '>=';
                        min_filter.expressionList[0].fieldName = 'answer';
                        min_filter.expressionList[1].value = filter_item.answer_2;
                        let max_filter = cloneDeep(query_types.survey_filter_item_input);
                        max_filter.operator = '<=';
                        max_filter.expressionList[0].fieldName = 'answer';
                        max_filter.expressionList[1].value = filter_item.answer_3;

                        between_container.expressionList.push(min_filter)
                        between_container.expressionList.push(max_filter)

                        survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(between_container);

                    }
                    else {
                        let survey_filter_item_input_1 = cloneDeep(query_types.survey_filter_item_input);
                        survey_filter_item_input_1.operator = `${filter_item.answer}`;
                        survey_filter_item_input_1.expressionList[0].fieldName = 'answer';
                        survey_filter_item_input_1.expressionList[1].value = `${filter_item.answer_2}`;
                        survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(survey_filter_item_input_1);
                    }
                }
                else if(filter_item.questionType == 'yes_or_no'){
                    let survey_filter_item_input_1 = cloneDeep(query_types.survey_filter_item_input);
                    survey_filter_item_input_1.expressionList[0].fieldName = 'answer';
                    if(filter_item.answer == 'yes' || filter_item.answer == 'no'){
                        survey_filter_item_input_1.expressionList[1].value = `${filter_item.answer}`;
                    }
                    else if (filter_item.answer == 'is_blank' || filter_item.answer == 'is_not_blank'){
                        survey_filter_item_input_1.operator = '!=';
                        survey_filter_item_input_1.expressionList[1].value = '';
                    }

                    survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(survey_filter_item_input_1);

                    if (filter_item.answer == 'is_blank'){
                        let survey_filter_not = cloneDeep(query_types.filter);
                        survey_filter_not.operator = 'Not';
                        survey_filter_not.expressionList = survey_filter_item_clone;
                        survey_filter_item_clone[0] = cloneDeep(survey_filter_not);
                    }
                }
                else if(filter_item.questionType == 'toggle'){
                    let survey_filter_item_input_1 = cloneDeep(query_types.survey_filter_item_input);
                    survey_filter_item_input_1.expressionList[0].fieldName = 'answer';
                    survey_filter_item_input_1.expressionList[1].value = true;
                    survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(survey_filter_item_input_1);
                    if(filter_item.answer == 'unselected'){
                        let survey_filter_not = cloneDeep(query_types.filter);
                        survey_filter_not.operator = 'Not';
                        survey_filter_not.expressionList = survey_filter_item_clone;
                        survey_filter_item_clone[0] = cloneDeep(survey_filter_not);
                    }
                }
                else if(filter_item.questionType == 'date'){
                    if(filter_item.answer == 'between'){
                        let between_container = cloneDeep(query_types.filter);
                        between_container.operator = 'and';

                        let min_filter = cloneDeep(query_types.survey_filter_item_input);
                        min_filter.operator = '>=';
                        min_filter.expressionList[0].fieldName = 'answer';
                        min_filter.expressionList[1].value = filter_item.date_from;
                        let max_filter = cloneDeep(query_types.survey_filter_item_input);
                        max_filter.operator = '<=';
                        max_filter.expressionList[0].fieldName = 'answer';
                        max_filter.expressionList[1].value = filter_item.date_to;

                        between_container.expressionList.push(min_filter)
                        between_container.expressionList.push(max_filter)

                        survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(between_container);

                    }
                    else {
                        let survey_filter_item_input_1 = cloneDeep(query_types.survey_filter_item_input);
                        survey_filter_item_input_1.operator = `${filter_item.answer}`;
                        survey_filter_item_input_1.expressionList[0].fieldName = 'answer';
                        survey_filter_item_input_1.expressionList[1].value = `${filter_item.date_from}`;
                        survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(survey_filter_item_input_1);
                    }
                }
                else {
                    // =, contains, is_blank, is_not_blank
                    let operator = '='
                    if(filter_item.answer == 'contains'){
                        operator = 'in'
                    }
                    else if (filter_item.answer == 'is_blank' || filter_item.answer == 'is_not_blank') {
                        operator = '!='
                    }
                    let survey_filter_item_input_1 = cloneDeep(query_types.survey_filter_item_input);
                    survey_filter_item_input_1.operator = operator;
                    survey_filter_item_input_1.expressionList[0].fieldName = 'answer';
                    survey_filter_item_input_1.expressionList[1].value = (filter_item.answer == 'is_blank' || filter_item.answer == 'is_not_blank') ? '' : filter_item.answer_2

                    if(filter_item.answer == 'contains'){
                        const temp = survey_filter_item_input_1.expressionList[0];
                        survey_filter_item_input_1.expressionList[0] = survey_filter_item_input_1.expressionList[1];
                        survey_filter_item_input_1.expressionList[1] = temp;
                    }

                    survey_filter_item_clone[0].expressionList[0].expressionList[1].expressionList.push(survey_filter_item_input_1);

                    if (filter_item.answer == 'is_blank'){
                        let survey_filter_not = cloneDeep(query_types.filter);
                        survey_filter_not.operator = 'Not';
                        survey_filter_not.expressionList = survey_filter_item_clone;
                        survey_filter_item_clone[0] = cloneDeep(survey_filter_not);
                    }

                }

                survey_filter_clone.operator = 'and'
                survey_filter_clone.expressionList = survey_filter_item_clone

                // @ts-ignore
                if(is_same_side_of_the_family_filter){
                    let survey_filter_clone1 = cloneDeep(survey_filter_clone)
                    let survey_filter_clone2 = cloneDeep(survey_filter_clone)
                    paternal_both_reference.push(survey_filter_clone1)
                    maternal_both_reference.push(survey_filter_clone2)

                    paternal_both_reference = paternal_both_reference[0].expressionList
                    maternal_both_reference = maternal_both_reference[0].expressionList
                }
                else{
                    reference.push(survey_filter_clone)
                    reference = reference[0].expressionList
                }
            }

            if (familyFilters.relationship != 'anyone'){
                let cloned_family_relationship_filter = cloneDeep(query_types.family_relationship_filter)
                if(familyFilters.relationship == 'blood_relatives'){
                    cloned_family_relationship_filter.expressionList[0].fieldName = 'is_blood_related_to_proband';
                    reference.push(cloned_family_relationship_filter)
                }
                else if(familyFilters.relationship == 'same_side_of_the_family'){

                    let operator = familyFilters.number_of_people_to_meet_criteria.relationalOperator
                    let count_value = familyFilters.number_of_people_to_meet_criteria.value
                    //count needed for this?
                    if(operator == 'any' || !count_value){
                        cloned_family_relationship_filter.expressionList[0].fieldName = 'is_blood_related_to_proband';
                        reference.push(cloned_family_relationship_filter)
                    }
                    else{
                        // cloned_family_relationship_filter.expressionList[0].fieldName = 'is_blood_related_to_proband';
                        // let cloned_family_relationship_filter1 = cloneDeep(cloned_family_relationship_filter)
                        // let cloned_family_relationship_filter2 = cloneDeep(cloned_family_relationship_filter)
                        // paternal_both_reference.push(cloned_family_relationship_filter1);
                        // maternal_both_reference.push(cloned_family_relationship_filter2);

                        // paternal_both_reference = paternal_both_reference[0].expressionList
                        // maternal_both_reference = maternal_both_reference[0].expressionList
                    }
                }
                else if(familyFilters.relationship == 'maternal_only'){
                    let clone_maternal_only_filter = cloneDeep(query_types.maternal_only_filter)
                    reference.push(clone_maternal_only_filter)
                }
                else if(familyFilters.relationship == 'paternal_only'){
                    let clone_paternal_only_filter = cloneDeep(query_types.paternal_only_filter)
                    reference.push(clone_paternal_only_filter)
                }
            }

            filter_index += 1;
        }

        // if(filterSets.length > 1){
        //     //@ts-ignore
        //     final_payload.query.expressionList[0].expressionList = final_payload.query.expressionList[0].expressionList.filter((exp: any) => exp.operator == "Exists")
        // }

        set_index += 1;
    }

    if (isEmpty(final_payload.query)){
        final_payload.query = {
            expressionList: [],
            expression_type: "expression",
            operator: "and"
        }
    }

    return final_payload;
}

const sortFilterSet = (filterSets: any) => {
    let sorted = filterSets.sort((a: any, b: any) => {
        if (a.andOr == 'or'){
            return +1
        }
        else {
            return -1
        }
    })

    return sorted;
}


// *Keep this commented off, this might be helpful for when we decide to be more complex on our filters and not forcing and's/or's
// to be the same in a filterset or between filtersets

// export function buildQueryPayload(filterType: string, filterSets: any){
//     console.log(filterType)
//     console.log(filterSets)

//     let final_payload = {
//         name: uuidv4(),
//         kind: filterType,
//         query: top_level_p_member_query_variable
//     }

//     // get ors first
//     let or_filterSets = filterSets.filter((set: any) => set.andOr == 'or')

//     let and_filterSets = filterSets.filter((set: any) => set.andOr == 'and')

//     for (let filter_set of or_filterSets){

//         let filterItems = filter_set.items

//         let disease_field_type_exists = filterItems.find((item: any) => item.fieldType == 'disease')
//         let genetic_testing_field_type_exists = filterItems.find((item: any) => item.fieldType == 'genetic_testing')
//         // other field types checks



//         if(disease_field_type_exists){
//             // @ts-ignore
//             final_payload.query.expressionList.push(p_member_disease_qualifier)
//         }

//         if(genetic_testing_field_type_exists){
//             if(disease_field_type_exists){
//                 // @ts-ignore
//                 final_payload.query.expressionList.expressionList.push(p_member_gene_qualifier)
//             }
//             else{
//                 // @ts-ignore
//                 final_payload.query.expressionList.push(p_member_gene_qualifier)
//             }
//         }

//         for (let filter_item of filterItems){
//         }
//     }

//     return final_payload;
// }
