import React, { Component } from 'react';
import { connect } from "react-redux";
import userAccountApi from '../../api/user-account-api';
import defaultColumns from "./default-columns.json";
import { createUUID } from '../react-flow-pedigree/utils';
import CopySurveyModal from './survey-copy-modal';
import SurveySearch from './survey-search';
import DeleteSurveyModal from './survey-delete-modal';
import * as helper_search from "../../helpers/helper-search";

interface  ModalProps{
    searchFilter: any;
    dispatch: (action:any) => void;
    surveyList: any;
    columns: any;
  }

interface State{
  surveyList: Array<any>;
  columns: Array<any>;
  searchText: string;
  showCopyModal: boolean;
  showdeleteSurveyModal: boolean;
  surveyToCopy: any;
  surveyToDeleteName: string;
  surveyToDeleteId: any;

}

type Survey = {
  name: string;
  organization: string;
  id: number
  translation_key: string;
}

type CopySurveyPayload = {
  newSurveyName: string;
  surveyToCopy: string;
}

type DeleteSurveyPayload = {
  surveyToDelete: string
}


class SurveyList extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          surveyList: [],
          columns: [],
          searchText: "",
          showCopyModal: false,
          surveyToCopy: null,
          surveyToDeleteName: "",
          showdeleteSurveyModal: false,
          surveyToDeleteId: null


        };

      this.openCopyModal = this.openCopyModal.bind(this);
      this.onClose = this.onClose.bind(this);

    }
  componentWillUnmount(): void {
    helper_search.updateSearchText(this.props.dispatch, "");
  }


  openCopyModal(survey: Survey){

    this.setState({
      showCopyModal: true,
      showdeleteSurveyModal: false,
      surveyToCopy: survey.id
    })
  }

  openDeleteModal(survey: Survey){

    this.setState({
      showCopyModal: false,
      showdeleteSurveyModal: true,
      surveyToDeleteName: survey.name,
      surveyToDeleteId: survey.id
    })
  }

  onClose(){
    this.setState({
      showCopyModal: false,
      showdeleteSurveyModal: false
    })
  }

  async onSave(title: string){

    let payload: CopySurveyPayload = {
      newSurveyName: title,
      surveyToCopy: this.state.surveyToCopy
    }

    try{
      await userAccountApi.create_new_survey_copy(payload)
    }catch(err){
      console.log(err)
    }finally{
      this.onClose()
    }

  }

  async deleteSurvey(){
    let payload: DeleteSurveyPayload = {
      surveyToDelete: this.state.surveyToDeleteId,
    }
    try{
      await userAccountApi.delete_survey(payload)
    }catch(err){
      console.log(err)
    }finally{
      this.onClose()
    }
  }



render() {
    return(
      <React.Fragment>
     
          <table id="surveyTable" className="table table-custom ">
              <thead>
                <tr>
                  {this.props.columns.map((item: any , index: number)=> {
                    return <th key={index}>{item.label}</th>
                  })}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {this.props.surveyList.map((survey: any) => {
                  return (
                    <React.Fragment key={createUUID()}>
                      <tr>
                        {this.props.columns.map((item: any) => {
                          if (item.value === 'name') {
                            return <td key={createUUID()}>{survey['name']}</td>
                          }
                          else if(item.value === 'organization'){
                            return <td key={createUUID()}>{survey['organizations']}</td>
                          }
                        })}
                        <td className="middle">
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openCopyModal(survey)}><i className="fa fa-eye"></i>&nbsp;Copy</button>
                          <button  className="btn btn-light btn-xs table-btn-view margin-right-six" onClick={()=>this.openDeleteModal(survey)}><i className="fa fa-eye"></i>&nbsp;Delete</button>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>


          {this.state.showCopyModal &&(
              <CopySurveyModal
                modalTitle='Copy Survey'
                onClose={this.onClose}
                onSave={(name)=> this.onSave(name)}
              />
            )}
          {this.state.showdeleteSurveyModal &&(
              <DeleteSurveyModal
                survey={this.state.surveyToDeleteName}
                modalTitle='Delete Survey'
                onClose={this.onClose}
                onDelete={()=>this.deleteSurvey()}
              />
            )}

      </React.Fragment>

    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyList);
