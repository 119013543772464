import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import * as helper_search from "../../helpers/helper-search";

type  ModalProps = {
    searchSurveys: () => void;
    dispatch: (action:any) => void;
    searchFilter: any;
    title: string;
}

interface State{

}

class SurveySearch extends Component<ModalProps, State> {

  constructor(props:ModalProps) {
    super(props);
    this.state = {
    };
    this.handleChange = this.handleChange.bind(this);
    this.clearText = this.clearText.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>){
    helper_search.updateSearchText(this.props.dispatch, event.target.value);
  }

  async clearText(){
    await helper_search.updateSearchText(this.props.dispatch, "");
    this.props.searchSurveys()
  }


  onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' || event.key === 'Backspace') {
      // ENTER key was pressed
      this.props.searchSurveys();
    }
  }

  render() {
    const searchVal = this.props.searchFilter.search_text
    return(
        <div style={{ display: "flex" }}>
        <input
          type="text"
          value={searchVal}
          onChange={this.handleChange}
          onKeyUp={this.onKeyUp}
        //   onFocus={this.handleFocus}
          id="search_text"
          name="search_text"
          placeholder={this.props.title}
          autoComplete="off"
          className="input-home-search search-results-searchBar"
        />

        <i
          className="fa fa-search fa-lg"
          onClick={this.props.searchSurveys}
          style={{
            display: "grid",
            position: "relative",
            alignContent: "center",
            right: "35px",
          }}
        ></i>

        {searchVal.length > 0 && (
            <a className={'delete-search'} >
                <i className="fa fa-times fa-lg" onClick={this.clearText}></i>
            </a>
        )}
      </div>
    );
  }

}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveySearch);
