import sdk from './sdk';
// import { readFileSync } from 'fs';
const base_uri = '/refinery'

// IE11 needs URL to end with a slash '/'

const get_redux_tree = async (member_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + base_uri + '/redux-tree/' +  member_id + '/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_redux_tree_deux = async (member_id, payload = {}) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + base_uri + '/redux-tree/' +  member_id + '/redux_tree/';
    let response = await sdk.request('get', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_progeny_family = async (family_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/archive/' +  family_id + '/get_family_details/';
    let response = await sdk.request('get', url, {cache: "True"});

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const get_progeny_archive_preferences = async () => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/progeny_archive_preference/get_progeny_archive_preferences/';
    let response = await sdk.request('get', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const create_progeny_archive_preferences = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/progeny_archive_preference/';
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const delete_progeny_archive_preferences = async (preference_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/progeny_archive_preference/' + preference_id + '/';
    let response = await sdk.request('delete', url);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const update_criteria_settings = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/risk/update_criteria_settings/'
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}

const task_manager_test = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/action_manager/run_task_from_file/'
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}
const account_dial_code_test = async () => {
  try {
    let payload = {
      'account_id': 1
    }

    let url = process.env.REACT_APP_ROOT_API_URL + '/account/get_account_dial_code/'
    let response = await sdk.request('get', url, payload);
    console.log(response)
    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err)
  }
}
export default {
  parse_error_message: sdk.parse_error_message,
  get_redux_tree,
  get_redux_tree_deux,
  get_progeny_family,
  get_progeny_archive_preferences,
  create_progeny_archive_preferences,
  delete_progeny_archive_preferences,
  update_criteria_settings,
  task_manager_test,
  account_dial_code_test
}
