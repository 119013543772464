import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import {Checkbox} from 'react-icheck';
import account_api from '../../../api/user-account-api';

class ModalRiskSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.riskSettings,
      competing_mortality: this.props.riskSettings.competing_mortality,
      boadicea_incidence_rates: 'USA',
      prs_breast: null,
      prs_ovarian: null,
      zscore_breast: null,
      zscore_ovarian: null,
      backdate_age: null,
      license: {
        boadicea: false,
        gail: false,
        claus: false,
        tyrer_cuzick: false,
        brcapro: false,
        mmrpro: false,
        pancpro: false,
        melapro: false,
        qrisk3: false
      }
    };

    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.changeBoadiceaIncidenceRate = this.changeBoadiceaIncidenceRate.bind(this);
    this.changePrsBreast = this.changePrsBreast.bind(this);
    this.changePrsOvarian = this.changePrsOvarian.bind(this);
    this.getStringValue = this.getStringValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const org = await account_api.get_org_by_id(this.props.session.user.organization_id);

    // Remove next two lines when gail and claus can be disabled
    let license = org.license;
    if(license) {
      this.setState({license: license, boadicea_incidence_rates: license.boadicea_incidence_rates});
    }
  }

  onChangeCheckBox(name, checked) {
    this.setState({ [name]: checked });
  }

  onClickSave(event) {
    this.props.onClickSave(this.state);
    this.props.onClose();
  }

  changeBoadiceaIncidenceRate(item) {
    if(item) this.setState({ boadicea_incidence_rates: item.value });
  }

  changePrsBreast(item) {
    if(item) {
      this.setState({ prs_breast: item.value });
    } else {
      this.setState({ prs_breast: "" });
    }
  }

  changePrsOvarian(item) {
    if(item) {
      this.setState({ prs_ovarian: item.value });
    } else {
      this.setState({ prs_ovarian: "" });
    }
  }

  getStringValue(key) {
    let val = this.state[key];
    if (val) return val;
    return "";
  }

  onChange(event) {
    const dataKey = event.target.name;
    const data = event.target.value;

    this.setState({[dataKey]: data})
  }

  render() {
    const boadicea_disabled = !this.state.license.boadicea;
    const tyrer_cuzick_disabled = !this.state.license.tyrer_cuzick;
    const gail_disabled = !this.state.license.gail;
    const claus_disabled = !this.state.license.claus;
    const brcapro_disabled = !this.state.license.brcapro;
    const mmrpro_disabled = !this.state.license.mmrpro;
    const pancpro_disabled = !this.state.license.pancpro;
    const melapro_disabled = !this.state.license.melapro;
    const qrisk3_disabled = !this.state.license.qrisk3;

    let standard_label = 'control-label';
    let disabled_label = standard_label + ' grayout';

    const incidence_rate_choices = [
      { value: 'UK', label: 'UK'},
      { value: 'Australia', label: 'Australia'},
      { value: 'Canada', label: 'Canada'},
      { value: 'USA', label: 'USA'},
      { value: 'Denmark', label: 'Denmark'},
      { value: 'Finland', label: 'Finland'},
      { value: 'Iceland', label: 'Iceland'},
      { value: 'New-Zealand', label: 'New-Zealand'},
      { value: 'Norway', label: 'Norway'},
      { value: 'Spain', label: 'Spain'},
      { value: 'Sweden', label: 'Sweden'},
      { value: 'Other', label: 'Other'},
    ];

    let incidence_rate = '';
    for(var item of incidence_rate_choices) {
      if(this.state.boadicea_incidence_rates && this.state.boadicea_incidence_rates.toLowerCase() === item.label.toLowerCase()) {
        incidence_rate = item;
      }
    }

    const breast_prs_choices = [
      { value: 'BCAC_77', label: 'BCAC-77'},
      { value: 'BCAC_313', label: 'BCAC-313'},
      { value: 'BCAC_3820', label: 'BCAC-3820'},
      { value: 'BRIDGES_306', label: 'BRIDGES-306'},
      { value: 'EGLH_CEN_301', label: 'EGLH-CEN-301'},
      { value: 'EGLH_CEN_303', label: 'EGLH-CEN-303'},
      { value: 'MAINZ_309', label: 'MAINZ-309'},
      { value: 'PERSPECTIVE_295', label: 'PERSPECTIVE-295'},
      { value: 'PRISMA_268', label: 'PRISMA-268'},
      { value: 'WISDOM_75', label: 'WISDOM-75'},
      { value: 'WISDOM_128', label: 'WISDOM-128'}
    ];

    let prs_breast = '';
    for(var item of breast_prs_choices) {
      if(this.state.prs_breast && this.state.prs_breast.toLowerCase() === item.value.toLowerCase()) {
        prs_breast = item;
      }
    }

    const ovarian_prs_choices = [
      { value: 'OC_EGLH_CEN_34', label: 'OC-EGLH-CEN-34'},
      { value: 'OCAC_36', label: 'OCAC-36'}
    ];

    let prs_ovarian = '';
    for(var item of ovarian_prs_choices) {
      if(this.state.prs_ovarian && this.state.prs_ovarian.toLowerCase() === item.value.toLowerCase()) {
        prs_ovarian = item;
      }
    }

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="risk-settings"
        id="risk-settings">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Risk Settings</h4>
            </div>

            <div className="modal-body">
              <form className="form-horizontal form-label-left form-custom">
                <p>Select the risk to run:</p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 radio-inline famhis-radio-inline">
                        <Checkbox
                          id="boadicea"
                          name="boadicea"
                          onChange={(e, checked) => {
                            this.onChangeCheckBox(e.target.name, checked)
                          }}
                          checkboxClass="icheckbox_flat-green"
                          increaseArea="10%"
                          checked={this.state.boadicea}
                          disabled={boadicea_disabled}
                        />
                        <label htmlFor="boadicea" className={!boadicea_disabled ? standard_label : disabled_label}>BOADICEA</label>
                      </div>

                      <div className="col-md-6">
                        <Select
                          value={incidence_rate}
                          name="boadicea_incidence_rates"
                          onChange={(item) => {
                            this.changeBoadiceaIncidenceRate(item);
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={incidence_rate_choices}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="prs_breast" className={!boadicea_disabled ? standard_label : disabled_label}>Breast PRS</label>
                        <Select
                          value={prs_breast}
                          name="prs_breast"
                          onChange={(item) => {
                            this.changePrsBreast(item);
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={breast_prs_choices}
                        />
                      </div>
                      
                      <div className="col-md-6">
                        <label htmlFor="zscore_breast" className={!boadicea_disabled ? standard_label : disabled_label}>Breast ZScore</label>
                        <input
                          name="zscore_breast"
                          id="zscore_breast"
                          onChange={this.onChange}
                          value={this.getStringValue("zscore_breast")}
                          type="text"
                          className="form-control normal-input-text"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="prs_ovarian" className={!boadicea_disabled ? standard_label : disabled_label}>Ovarian PRS</label>
                        <Select
                          value={prs_ovarian}
                          name="prs_ovarian"
                          onChange={(item) => {
                            this.changePrsOvarian(item);
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={ovarian_prs_choices}
                        />
                      </div>
                      
                      <div className="col-md-6">
                        <label htmlFor="zscore_ovarian" className={!boadicea_disabled ? standard_label : disabled_label}>Ovarian ZScore</label>
                        <input
                          name="zscore_ovarian"
                          id="zscore_ovarian"
                          onChange={this.onChange}
                          value={this.getStringValue("zscore_ovarian")}
                          type="text"
                          className="form-control normal-input-text"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="backdate_age" className={!boadicea_disabled ? standard_label : disabled_label}>Backdate Age</label>
                        <input
                          name="backdate_age"
                          id="backdate_age"
                          onChange={this.onChange}
                          value={this.getStringValue("backdate_age")}
                          type="text"
                          className="form-control normal-input-text"
                        />
                      </div>
                      
                      <div className="col-md-6">
                        
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="claus"
                      name="claus"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.claus}
                      disabled={claus_disabled}
                    />
                    <label htmlFor="claus" className={!claus_disabled ? standard_label : disabled_label}>CLAUS</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="gail"
                      name="gail"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.gail}
                      disabled={gail_disabled}
                    />
                    <label htmlFor="gail" className={!gail_disabled ? standard_label : disabled_label}>GAIL</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="tyrer_cuzick"
                      name="tyrer_cuzick"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.tyrer_cuzick}
                      disabled={tyrer_cuzick_disabled}
                    />
                    <label htmlFor="tyrer_cuzick" className={!tyrer_cuzick_disabled ? standard_label : disabled_label}>TYRER-CUZICK</label>
                  </div>

                  {!tyrer_cuzick_disabled && (
                    <div>
                      <label htmlFor="competing_mortality" className={standard_label}>Competing Mortality</label>
                      <div className="col-md-2">
                        <div className="checkbox">
                          <label className="switch">
                            <input
                              name="competing_mortality"
                              id="competing_mortality"
                              onChange={() => this.setState({competing_mortality: !this.state.competing_mortality})}
                              checked={this.state.competing_mortality}
                              type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="brcapro"
                      name="brcapro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.brcapro}
                      disabled={brcapro_disabled}
                    />
                    <label htmlFor="brcapro" className={!brcapro_disabled ? standard_label : disabled_label}>BRCAPRO</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="mmrpro"
                      name="mmrpro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.mmrpro}
                      disabled={mmrpro_disabled}
                    />
                    <label htmlFor="mmrpro" className={!mmrpro_disabled ? standard_label : disabled_label}>MMRpro</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="pancpro"
                      name="pancpro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.pancpro}
                      disabled={pancpro_disabled}
                    />
                    <label htmlFor="pancpro" className={!pancpro_disabled ? standard_label : disabled_label}>PANCpro</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="melapro"
                      name="melapro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.melapro}
                      disabled={melapro_disabled}
                    />
                    <label htmlFor="melapro" className={!melapro_disabled ? standard_label : disabled_label}>MELApro</label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="qrisk3"
                      name="qrisk3"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.qrisk3}
                      disabled={qrisk3_disabled}
                    />
                    <label htmlFor="qrisk3" className={!qrisk3_disabled ? standard_label : disabled_label}>QRISK3</label>
                  </div>
                </div>

              </form>
            </div>

            { ( boadicea_disabled || gail_disabled || claus_disabled || tyrer_cuzick_disabled || brcapro_disabled || mmrpro_disabled || pancpro_disabled || melapro_disabled || qrisk3_disabled) &&
              <div>
                <p className="row-centered">Disabled risk models are available but not currently activated for your account.<br/>Contact FamGenix if you would like to add them.</p>
              </div>
            }

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className="btn btn-dark">Save</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalRiskSettings;
