import React, { Component } from 'react';
import { connect } from "react-redux";

import PatientSurveyFieldCanvas from './patient-survey/patient-survey-field-canvas';

import sdk from '../../../api/sdk';
import helper_survey_api from "../../../helpers/helper-survey-api";
import mock_survey_data from "../../survey/mock-survey-data";

import i18n from 'i18next';

// three states rendering, found, not_found
const states = {
  RENDERING: 'rendering',
  FOUND: 'found',
  NOT_FOUND: 'not_found',
};

// this is the clinicians version of the custom survey
class PatientSurvey extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: states.RENDERING,
      survey: mock_survey_data.empty_survey,
      completed_survey: null,
      completed_survey_answers: [],
      proband: null,
      proband_diseases: [],
      proband_disease_skip_logics: [],
      lang: "en"
    };

    // holds the ids of survey answers in the process of being deleted
    // this is used so we don't send multiple requests to delete the same answer
    this.deletes_in_progress = [];

    this.surveyNotFound = this.surveyNotFound.bind(this);
    this.surveyFoundCallback = this.surveyFoundCallback.bind(this);

    this.saveCompletedSurveyAnswer = this.saveCompletedSurveyAnswer.bind(this);
    this.deleteCompletedSurveyAnswer = this.deleteCompletedSurveyAnswer.bind(this);

    this.completedSurveyAnswerCallback = this.completedSurveyAnswerCallback.bind(this);
    // this.deleteSurveyAnswerCallback = this.deleteSurveyAnswerCallback.bind(this);

    this.inDeletesInProgress = this.inDeletesInProgress.bind(this);
    this.addToDeletesInProgress = this.addToDeletesInProgress.bind(this);
    this.removeFromDeletesInProgress = this.removeFromDeletesInProgress.bind(this);

    this.callCreateSurveyAnswerEndpoint = this.callCreateSurveyAnswerEndpoint.bind(this);
    this.callUpdateSurveyAnswerEndpoint = this.callUpdateSurveyAnswerEndpoint.bind(this);
    this.callDeleteSurveyAnswerEndpoint = this.callDeleteSurveyAnswerEndpoint.bind(this);

    this.logError = this.logError.bind(this);

    this.onLangClick = this.onLangClick.bind(this);
  }

  componentDidMount() {

    let access_token = null;
    let tokens = sdk.tokens_from_cookie();
    access_token = tokens.token.accessToken;

    let member_id = this.props.memberID;

    if (this.props.surveyId &&
      member_id) {

      const headers = { accessToken: access_token };
      const payload = {
        clinician_id: this.props.session.user.clinician_id,
        survey_id: this.props.surveyId,
        proband_id: member_id
      };

      // pull the survey from DB
      helper_survey_api.get_survey(payload, headers, this.surveyFoundCallback, (error) => {
        this.surveyNotFound();
      });

    } else {
      this.surveyNotFound();
    }

  }

  surveyNotFound() {
    this.setState({
      status: states.NOT_FOUND
    });
  }

  surveyFoundCallback(survey_data) {
    if (survey_data) {
      const completed_survey = survey_data.completed_survey;

      // set the returned survey in state
      this.setState({
        status: states.FOUND,
        survey: survey_data.survey,
        completed_survey: survey_data.completed_survey,
        completed_survey_answers: survey_data.completed_survey_answers,
        proband: survey_data.proband,
        proband_diseases: survey_data.proband_diseases,
        proband_disease_skip_logics: survey_data.proband_disease_skip_logics
      });
    } else {
      this.surveyNotFound();
    }
  }

  saveCompletedSurveyAnswer(payload) {
    // payload = {
    //   field_type: null,
    //   member_id: null,
    //   master_question_id: null,
    //   master_question_choice_id: null,
    //   answer: null
    // }
    let member_id = this.props.memberID;

    payload.member_id = member_id;

    // trim the string answer from the user
    if (typeof(payload.answer) === "string") payload.answer = payload.answer.trim();

    // lookup to see if the user has a CompletedSurveyAnswer record already by looking in the completed_survey_answers
    let answers = this.state.completed_survey_answers.filter((answer) => {
      return parseInt(payload.master_question_id, 10) === parseInt(answer.master_question_id, 10);
    });

    let access_token = null;
    let tokens = sdk.tokens_from_cookie();
    access_token = tokens.token.accessToken;
    const headers = { accessToken: access_token };

    if (payload.field_type && payload.field_type === "select_all_that_apply") {

      // use the master_question_choice_id to narrow it down to see if there is already a completed survey answer
      // if no answer was found then create a new one with master_question_choice_id
      answers = answers.filter((answer) => {
        return parseInt(answer.master_question_choice_id, 10) === parseInt(payload.master_question_choice_id, 10);
      });

      if (answers.length === 0) {

        // no previous answer was found so hit the create completed survey answer endpoint
        this.callCreateSurveyAnswerEndpoint(payload, headers);

      } else {

        // unchecking the question choice, delete the answer
        if (answers.length === 1) {
          payload.id = answers[0].id;
          this.callDeleteSurveyAnswerEndpoint(payload, headers);
        }

      }

    } else {

      if (answers.length === 1) {

        // they already have an answer, hit the update completed survey answer endpoint
        payload.id = answers[0].id;
        this.callUpdateSurveyAnswerEndpoint(payload, headers);


      } else if(answers.length > 1) {

        console.log("Error: Should only have multiple answers if question type Select All That Apply");

      } else {

        // no previous answer was found so hit the create completed survey answer endpoint
        this.callCreateSurveyAnswerEndpoint(payload, headers);

      }

    }
  }

  deleteCompletedSurveyAnswer(answer) {

    if (!this.inDeletesInProgress(answer.id)) {
      this.addToDeletesInProgress(answer.id)

      let access_token = null;
      let tokens = sdk.tokens_from_cookie();
      access_token = tokens.token.accessToken;

      const headers = { accessToken: access_token };

      const payload = {
        id: answer.id,
        master_question_id: answer.master_question_id
      };

      this.callDeleteSurveyAnswerEndpoint(payload, headers);
    }
  }

  completedSurveyAnswerCallback(completed_survey_answers, master_question_id) {
    // update completed_survey_answers with data returned
    if (completed_survey_answers) {
      this.setState({ completed_survey_answers: completed_survey_answers });

      let access_token = null;
      let tokens = sdk.tokens_from_cookie();
      access_token = tokens.token.accessToken;

      // send notification about answer update
      if (master_question_id) {
        const headers = { accessToken: access_token };
        const notification_payload = {
          master_question_id: master_question_id,
          proband_id: this.props.memberID
        };

        // send update notification to proband
        helper_survey_api.send_notification_to_proband_for_updating_survey_answer(notification_payload, headers, () => console.log("Completed"), (error) => {
          console.log(error);
        });
      }

    }
  }

  // deleteSurveyAnswerCallback(completed_survey_answers) {
  //   // update completed_survey_answers with data returned
  //   if (completed_survey_answers) {
  //     this.setState({ completed_survey_answers: completed_survey_answers });

  //     // collect all completed survey answer ids
  //     const ids = completed_survey_answers.map((a) => a.id);

  //     let id_to_remove = null;

  //     // run through and see which one of the deletes_in_progress is
  //     // not in the completed answers anymore then remove from the array
  //     for (let id in this.deletes_in_progress) {
  //       if (!ids.includes(id)) {
  //         id_to_remove = id;
  //         break;
  //       }
  //     }

  //     if (id_to_remove) this.removeFromDeletesInProgress(id_to_remove);

  //   }
  // }

  inDeletesInProgress(id) {
    return this.deletes_in_progress.includes(id)
  }

  addToDeletesInProgress(id) {
    this.deletes_in_progress.push(id);
  }

  removeFromDeletesInProgress(id_to_remove) {
    this.deletes_in_progress = this.deletes_in_progress.filter((id) => {
      return id !== id_to_remove
    });
  }

  callCreateSurveyAnswerEndpoint(payload, headers) {
    helper_survey_api.create_completed_survey_answer(
      payload,
      headers,
      this.completedSurveyAnswerCallback,
      this.logError
    );
  }

  callUpdateSurveyAnswerEndpoint(payload, headers) {
    helper_survey_api.update_completed_survey_answer(
      payload,
      headers,
      this.completedSurveyAnswerCallback,
      this.logError
    );
  }

  callDeleteSurveyAnswerEndpoint(payload, headers) {
    helper_survey_api.delete_completed_survey_answer(
      payload,
      headers,
      this.completedSurveyAnswerCallback,
      this.logError
    );
  }

  logError(error) {
    console.log(error);
  }

  onLangClick(e) {
    this.setState({ lang: e.currentTarget.value });
    i18n.changeLanguage(e.currentTarget.value);
  }

  render() {

    const lang = this.state.lang;

    let page_content = null;
    if (this.state.status === states.RENDERING) {

      page_content = (
        <div>Loading...</div>
      );

    } else if (this.state.status === states.FOUND) {

      page_content = (
        <div>
          {/*<div className="row">
            <div className="col-md-1">
              <input
                style={{width: "20px"}}
                type="radio"
                id="en"
                value="en"
                onClick={this.onLangClick}
                checked={lang === "en"}
              />
              <label htmlFor="en">
                en
              </label>
            </div>

            <div className="col-md-1">
              <input
                style={{width: "20px"}}
                type="radio"
                id="fr"
                value="fr"
                onClick={this.onLangClick}
                checked={lang === "fr"}
              />
              <label htmlFor="fr">
                fr
              </label>
            </div>

            <div className="col-md-1">
              <input
                style={{width: "20px"}}
                type="radio"
                id="es"
                value="es"
                onClick={this.onLangClick}
                checked={lang === "es"}
              />
              <label htmlFor="es">
                es
              </label>
            </div>

            <div className="col-md-1">
              <input
                style={{width: "20px"}}
                type="radio"
                id="nl"
                value="nl"
                onClick={this.onLangClick}
                checked={lang === "nl"}
              />
              <label htmlFor="nl">
                nl
              </label>
            </div>

            <div className="col-md-1">
              <input
                style={{width: "20px"}}
                type="radio"
                id="de"
                value="de"
                onClick={this.onLangClick}
                checked={lang === "de"}
              />
              <label htmlFor="de">
                de
              </label>
            </div>
          </div>*/}

          <PatientSurveyFieldCanvas
            survey={this.state.survey}
            proband={this.state.proband}
            proband_diseases={this.state.proband_diseases}
            proband_disease_skip_logics={this.state.proband_disease_skip_logics}
            completedSurveyAnswers={this.state.completed_survey_answers}
            saveCompletedSurveyAnswer={(payload) => this.saveCompletedSurveyAnswer(payload)}
            deleteCompletedSurveyAnswer={(answer) => this.deleteCompletedSurveyAnswer(answer)}
          />
        </div>
      );

    } else {

      // NOT FOUND
      page_content = (
        <div></div>
      );

    }

    return (

      <React.Fragment>
        {page_content}
      </React.Fragment>

    );
  }
}

export default PatientSurvey;
