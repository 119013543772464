import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import LoginPage from './pages/p-login-page';
import PatientPage from './pages/p-patient-page';
import FamilyTree from './pages/p-family-tree-page';
import FamilyHistory from './pages/p-family-history-page';
import PedigreePage from './pages/p-pedigree-page';
import PatientPedigreeLandingPage from './pages/p-patient-pedigree-landing-page';
import PatientInformation from './pages/p-patient-information-page';
import SubAccountStaffPage from './pages/p-sub-account-staff-page';
import UserProfilePage from './pages/p-user-profile-page';
import AccountsPage from './pages/p-accounts-page';
import NewAccountPage from './pages/p-new-account-page';
import SuperAccountPage from './pages/p-super-account-page';
import SubAccountPage from './pages/p-sub-account-page';
import ClinicianSuperAccountPage from './pages/p-clinician-super-account-page';
import ClinicianSubAccountPage from './pages/p-clinician-sub-account-page';
import QueryPage from './pages/p-query-page';
import ReadOnlyPatients from './pages/p-read-only-patient-results'
import AuthenticateEMRPage from './pages/p-login-read-only'
import SurveyListPage from './components/survey/survey-list-page'
import PasswordResetPage from './pages/p-password-reset-page';
import CreatePasswordPage from './pages/p-create-password-page';

import settings from './configs/settings';

import route_helper from './route-helper';
import samlLogin from './components/login/saml-login';
import OAuthLogin from './components/login/oauth-login';
import SecurityInfo from './components/well-known/security-info';

import AuthenticatorAuthenticatePage from './pages/p-authenticator-authenticate-page';
import AuthenticatorRegistrationPage from './pages/p-authenticator-registration-page';

import api_auth from './api/authentication'

const standardAccountTypes = [
  settings.app_constants.standardOrg.value, 
  settings.app_constants.trialOrg.value, 
  settings.app_constants.freeOrg.value, 
  settings.app_constants.studentOrg.value, 
  settings.app_constants.individualOrg.value
];

const getUserAccountType = (user) => {
  if(user !== null &&
    user !== undefined &&
    user.account_type !== null &&
    user.account_type !== undefined) {return user.account_type;}
  return settings.app_constants.standardOrg.value; // just default to standard account type for now
};

const isUserAdmin = (user) => {
  if(user !== null &&
    user !== undefined &&
    user.admin !== null &&
    user.admin !== undefined &&
    typeof(user.admin) === 'boolean') {return user.admin;}
  return false; // just default to to false if needed
};

export default ({authenticated, user}) => {

  /*
  | - - - - - - - - - - - -
  | This section handles when user is not authenticated
  |
  */
  if(authenticated === false) {

    if(window.location.pathname !== "/") {
      let is_password_reset = window.location.pathname.search(/password-reset/);
      let create_password = window.location.pathname.search(/create-password/);
      let emr_authenticate = window.location.pathname.search(/emrintegration/)
      let saml_login = window.location.pathname.search(/login/)
      let oauth = window.location.pathname.search(/oauth/)
      let patient_pedigree_landing_page = window.location.pathname.search(/patient-pedigree/)
      let pedigree = window.location.pathname.search(/pedigree/)
      let well_known = window.location.pathname.search(/\.well-known\/security\.txt/);

      if(is_password_reset === 1) {
        return <Route path={route_helper.password.password_reset_confirmation} component={PasswordResetPage} />
      } else if(create_password === 1) {
        return <Route path={route_helper.password.create_password} component={CreatePasswordPage} />
      } else if(emr_authenticate === 1){
       return <Route path={route_helper.read_only.emrintegration} component={AuthenticateEMRPage} />
      } else if(saml_login == 1){
        return <Route path={route_helper.saml_login.authentication} component={samlLogin} />
      } else if(oauth == 1){
        return <Route path={route_helper.oauth_login.authentication} component={OAuthLogin} />
      } else if(patient_pedigree_landing_page == 1){
        return <Route path={route_helper.pedigree.patient_pedigree_landing} component={PatientPedigreeLandingPage} />
      } else if(pedigree == 1){
        return <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
      } else if(well_known == 1){
        return <Route path={route_helper.well_known.security_check} component={SecurityInfo} />
      } else {
        return <Route render={(props) => <Redirect to={{pathname: route_helper.root, state: {from: props.location}}} />} />
      }
    } else {
      return <Route exact path={route_helper.root} component={LoginPage} />
    }

  }

  /* - - - - - - - - - */


  /*
  | - - - - - - - - - - - -
  | This section handles when user has successfuly login and authenticated
  |
  */

  if(window.location.pathname === "/") {

    if(getUserAccountType(user) === settings.app_constants.famhisOrg.value) {
      return <Route render={(props) => {
        const { from } = props.location.state || { from: { pathname: route_helper.administrative.fh_dashboard } }
        return <Redirect to={from} />
      }}/>
    } else {
      let is_emr_session = sessionStorage.getItem('is_emr_session')
      is_emr_session = is_emr_session == 'true' ? true : false

      if (is_emr_session){
        api_auth.logout();
      }

      return <Route render={(props) => {
        const { from } = props.location.state || { from: { pathname: route_helper.query.query_root } }
        return <Redirect to={from} />
      }}/>
    }

  } else {

    // Secured Pages
    if(getUserAccountType(user) === settings.app_constants.famhisOrg.value) {
      return (
        <React.Fragment>
          <Route path={route_helper.administrative.fh_dashboard} component={AccountsPage} />
          <Route path={route_helper.administrative.fh_new_account} component={NewAccountPage} />
          <Route path={route_helper.administrative.fh_super_account} component={SuperAccountPage} />
          <Route path={route_helper.administrative.fh_sub_account} component={SubAccountPage} />
          <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
          <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
          <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
          <Route path={route_helper.survey.survey_list} component={SurveyListPage}/>
        </React.Fragment>
      );
    } else {

      let is_emr_session = sessionStorage.getItem('is_emr_session')
      is_emr_session = is_emr_session == 'true' ? true : false

      if (is_emr_session && !window.location.pathname.includes("/oauth") && !window.location.pathname.includes("/emrintegration") && !window.location.pathname.includes("/emr_patients") && !window.location.pathname.startsWith("/pedigree/")){
        api_auth.logout();
      }

      return (
        <React.Fragment>
          {getUserAccountType(user) === settings.app_constants.superOrg.value && isUserAdmin(user) &&
            <React.Fragment>
              <Route path={route_helper.query.query_root} component={QueryPage} />
              <Route path={route_helper.patient.patient_root} component={PatientPage} />
              <Route path={route_helper.patient.patient_information} component={PatientInformation} />
              <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
              <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
              <Route path={route_helper.read_only.emr_patient_search} component={ReadOnlyPatients} />
              <Route path={route_helper.administrative.clinician_sub_account} component={ClinicianSubAccountPage} />
              <Route path={route_helper.administrative.clinician_super_account} component={ClinicianSuperAccountPage}/>
              <Route path={route_helper.family.family_tree} component={FamilyTree} />
              <Route path={route_helper.family.family_history} component={FamilyHistory} />
              <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
              <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
            </React.Fragment>
          }

          {getUserAccountType(user) === settings.app_constants.superOrg.value && !isUserAdmin(user) &&
            <React.Fragment>
              <Route path={route_helper.query.query_root} component={QueryPage} />
              <Route path={route_helper.patient.patient_root} component={PatientPage} />
              <Route path={route_helper.patient.patient_information} component={PatientInformation} />
              <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
              <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
              <Route path={route_helper.read_only.emr_patient_search} component={ReadOnlyPatients} />
              <Route path={route_helper.family.family_tree} component={FamilyTree} />
              <Route path={route_helper.family.family_history} component={FamilyHistory} />
              <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
              <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
            </React.Fragment>
          }

          {standardAccountTypes.includes(getUserAccountType(user)) && isUserAdmin(user) &&
            <React.Fragment>
              <Route path={route_helper.query.query_root} component={QueryPage} />
              <Route path={route_helper.patient.patient_root} component={PatientPage} />
              <Route path={route_helper.patient.patient_information} component={PatientInformation} />
              <Route path={route_helper.family.family_tree} component={FamilyTree} />
              <Route path={route_helper.family.family_history} component={FamilyHistory} />
              <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
              <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
              <Route path={route_helper.read_only.emr_patient_search} component={ReadOnlyPatients} />
              <Route path={route_helper.administrative.clinician_super_account} component={ClinicianSuperAccountPage} />
              <Route path={route_helper.administrative.clinician_sub_account} component={ClinicianSubAccountPage} />
              <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
              <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
            </React.Fragment>
          }

          {standardAccountTypes.includes(getUserAccountType(user)) && !isUserAdmin(user) &&
            <React.Fragment>
              <Route path={route_helper.query.query_root} component={QueryPage} />
              <Route path={route_helper.patient.patient_root} component={PatientPage} />
              <Route path={route_helper.patient.patient_information} component={PatientInformation} />
              <Route path={route_helper.family.family_tree} component={FamilyTree} />
              <Route path={route_helper.family.family_history} component={FamilyHistory} />
              <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
              <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
              <Route path={route_helper.read_only.emr_patient_search} component={ReadOnlyPatients} />
              <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
              <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
            </React.Fragment>
          }

          {getUserAccountType(user) === settings.app_constants.subOrg.value && isUserAdmin(user) &&
            <React.Fragment>
              <Route path={route_helper.query.query_root} component={QueryPage} />
              <Route path={route_helper.read_only.emr_patient_search} component={ReadOnlyPatients} />
              <Route path={route_helper.patient.patient_root} component={PatientPage} />
              <Route path={route_helper.patient.patient_information} component={PatientInformation} />
              <Route path={route_helper.family.family_tree} component={FamilyTree} />
              <Route path={route_helper.family.family_history} component={FamilyHistory} />
              <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
              <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
              {/*<Route path={route_helper.administrative.staff} component={SubAccountStaffPage} />*/}
              <Route path={route_helper.administrative.clinician_sub_account} component={ClinicianSubAccountPage} />
              <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
              <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
            </React.Fragment>
          }

          {getUserAccountType(user) === settings.app_constants.subOrg.value && !isUserAdmin(user) &&
            <React.Fragment>
              <Route path={route_helper.query.query_root} component={QueryPage} />
              <Route path={route_helper.patient.patient_root} component={PatientPage} />
              <Route path={route_helper.patient.patient_information} component={PatientInformation} />
              <Route path={route_helper.family.family_tree} component={FamilyTree} />
              <Route path={route_helper.family.family_history} component={FamilyHistory} />
              <Route path={route_helper.pedigree.pedigree_root} component={PedigreePage} />
              <Route path={route_helper.administrative.user_profile} component={UserProfilePage} />
              <Route path={route_helper.read_only.emr_patient_search} component={ReadOnlyPatients} />
              <Route path={route_helper.authenticator.authenticate} component={AuthenticatorAuthenticatePage}/>
              <Route path={route_helper.authenticator.registration} component={AuthenticatorRegistrationPage}/>
            </React.Fragment>
          }

          <Route path='empty' component={null} key="empty-placeholder" />
        </React.Fragment>
      );
    }
  }

  /* - - - - - - - - - */


}
