import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavTop from '../nav-top';
import SurveyList from './survey-list';
import SurveySearch from './survey-search';
import SurveyQuestionList from './survey-questions';
import userAccountApi from '../../api/user-account-api';
import defaultColumns from "./default-columns.json";
import defaultQuestionColumns from "./default-question-colummns.json";
import { connect } from "react-redux";
import surveyQuestions from './survey-questions';

interface ModalProps {
    user: any;
    history: any;
    searchFilter: any;
  }


interface State{
  surveyList: any;
  surveyColumns: any;
  activeTab: string;
  openAddModal: boolean;
  questions: any;
  questionColumns: any;

}

class SurveyListPage extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          surveyList: [],
          surveyColumns: [],
          activeTab: 'surveys',
          questionColumns: [],
          questions: [],
          openAddModal: false

        };

      this.componentDidMount = this.componentDidMount.bind(this);
      this.searchSurveys = this.searchSurveys.bind(this);
      this.fetchSurveys = this.fetchSurveys.bind(this);
      this.updateActiveTab = this.updateActiveTab.bind(this);
      this.fetchQuestions = this.fetchQuestions.bind(this); 
    }
  async componentDidMount(){
    await this.fetchSurveys()
    await this.fetchQuestions()
  }
  
  async fetchSurveys(){
    let payload = {
      search_text: this.props.searchFilter.search_text
    }
    try{
      let surveys = await userAccountApi.get_surveys_with_orgs(payload)
      this.setState({
        surveyList: surveys,
        surveyColumns: defaultColumns
      })
    }catch(err){
      console.dir(err)
    }
  }
  async searchSurveys(){
    if(this.state.activeTab === 'surveys'){
      await this.fetchSurveys()
    }
    if(this.state.activeTab === 'questions'){
      await this.fetchQuestions()
    }
  }

  async fetchQuestions(){
    let payload = {
      search_text: this.props.searchFilter.search_text
    }

    try{
      let questions = await userAccountApi.get_surveys_questions(payload)
      console.log(questions)
      this.setState({
        questions: questions,
        questionColumns: defaultQuestionColumns
      })
    }catch(err){
      console.dir(err)
    }
  }

  
  updateActiveTab = (tab: string) => {
    this.setState({activeTab: tab})
  }

render() {
    return ReactDOM.createPortal(
    <React.Fragment>
      <NavTop
        history={this.props.history}
        page='survey-results'
      />
        <section className="wow fadeIn section">
          <div className="container">
            <SurveySearch
              title={ this.state.activeTab === 'surveys' ? "Search Surveys..." : 'Search Questions...'}
              searchSurveys={this.searchSurveys}
            />
             
              <div className="row">
          		  <div className="col-md-16" style={{marginRight: '-13px', marginTop: '20px'}}>
                  <ul className="nav nav-tabs">
                      <li className={this.state.activeTab=== 'surveys'? "active" : ""}>
                          <button className="btn-link"  onClick={() => this.updateActiveTab('surveys')}>
                              Surveys
                          </button>
                      </li>
                      <li className={this.state.activeTab=== 'questions'? "active" : ""}>
                          <button className="btn-link" onClick={() => this.updateActiveTab('questions')}>
                              Questions
                          </button>
                      </li>
                      {this.state.activeTab === 'questions' && (
                        <div className="ms-auto" style={{float: 'right' }}>
                          <button onClick={()=>this.setState({openAddModal: true})} className="btn btn-light btn-xs" style={{marginBottom: '7px'}} > Add Question</button>
                        </div>
                      )}
                    </ul>
                    
                  </div>
              
                <div className="col-md-12">
                  {this.state.activeTab === 'surveys' &&(
                    <SurveyList
                      surveyList={this.state.surveyList}
                      columns={this.state.surveyColumns}
                    />
                  )}
                  {this.state.activeTab === 'questions' && (
                    <SurveyQuestionList
                      questionList={this.state.questions}
                      columns={this.state.questionColumns}
                      openAddModal={this.state.openAddModal}
                      closeAddModal={()=>this.setState({openAddModal: false})}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
    </React.Fragment>,
      
    document.body
    );
  };
}


const redux_state = (state: any) => ({
  searchFilter: state.search.searchFilter,
});

const redux_actions = (dispatch: any) => ({
    dispatch: (action: any) => dispatch(action)
});

export default connect(redux_state, redux_actions)(SurveyListPage);

