import * as model from '../model-states/m-family-tree';
import * as patient_actions from '../store/patient/actions';
import helpers from './index'
import helper_family_api from './helper-family-api'
import * as helper_health_history from './helper-health-history'
import family_api from '../api/family-api'
import { cloneDeep } from 'lodash'
import risk_api from '../api/risk-api';
import refinery_api from '../api/refinery-api'
import { risk_settings as default_risk_settings } from '../store/patient/default';
import { risk_results as default_risk_results } from '../store/patient/default';

const populateProband = async (dispatch, member_id) => {

  resetFamilyRedux(dispatch);
  // await helpers.delay(1)
  // await helpers.delay(5)

  let proband = await populateProbandFromAPI(dispatch, member_id);

  // await populateSiblingsFromAPI(dispatch, proband.id, proband.father.id, proband.mother.id)
  // await populateProbandParentOtherPartnersFromAPI(dispatch, 'father', proband.father.id, proband.mother.id)
  // await populateProbandParentOtherPartnersFromAPI(dispatch, 'mother', proband.father.id, proband.mother.id)

  await helpers.delay(0.5);
  return proband;
}

const populatePartnersFromAPI = async (dispatch, ownerRkey, spouse) => {
  try {
    let spouse_id = spouse.id
    if(spouse_id == null) return

    // Clear redux before repopulating
    dispatch(patient_actions.delete_partner({ownerRkey}))

    // Fetch API
    let partners = await family_api.get_member_memberid_partners(spouse_id)
    for(var partner of partners) {
      var partner_from_model = model.createPartner()
      var partner_data = Object.assign({}, partner_from_model, partner)

      // Save to Redux
      // partner_data.rkey = 'member_' + partner_data.id
      savePartnerToRedux(dispatch, ownerRkey, partner_data)
      saveProfileToRedux(dispatch, partner_data)

      helper_health_history.saveHistoryDiseasesToRedux(dispatch, partner_data.rkey, partner_data.diseases)
      helper_health_history.saveHistoryGenesToRedux(dispatch, partner_data.rkey, partner_data.genetic_testing)

      // Crawl lower tree
      await populateChildrenFromAPI(dispatch, partner_data, spouse)
      await populatePartnerRelationshipFromAPI(dispatch, ownerRkey, spouse, partner_data)

    }
  } catch (error) {
    helpers.logger(error)
  }
}

const populatePartnerRelationshipFromAPI = async(dispatch, ownerRkey, spouse, partner) => {
  try {

    let father_id = null;
    let mother_id = null;
    if(spouse.gender.toLowerCase() == 'm') {
      father_id = spouse.id;
      mother_id = partner.id;
    } else {
      father_id = partner.id;
      mother_id = spouse.id;
    }

    let relationship_data = await family_api.get_partner_relationship(father_id, mother_id)
    relationship_data = Object.assign({},
      cloneDeep(model.partner.relationship_data),
      relationship_data
    )

    let profile = { rkey: partner.rkey, relationship_data }
    savePartnerToRedux(dispatch, ownerRkey, profile)
    saveProfileToRedux(dispatch, profile)

  } catch (error) {
    helpers.logger(error)
  }
}

const populateChildrenFromAPI = async (dispatch, partner, spouse) => {
  try {
    if(spouse == null) return

    let partnerRkey = partner.rkey
    let partner_id = partner.id
    let spouse_id = spouse.id
    let father_id = spouse.id
    let mother_id = partner.id
    if(spouse.gender && spouse.gender.toLowerCase() == 'f') {
      father_id = partner.id
      mother_id = spouse.id
    }

    // Clear partner son daugher redux before repopulating
    dispatch(patient_actions.delete_son_daughter({partnerRkey}))

    // Call API
    let children = await family_api.get_members_memberid_children(spouse_id, partner_id)
    for(var child of children) {
      // Save to Redux
      var child_from_model = model.createSonDaughter(child.gender)
      var child_data = Object.assign({}, child_from_model, child)
      child_data.father_id = father_id
      child_data.mother_id = mother_id

      // Save to Redux
      saveProfileToRedux(dispatch, child_data)
      saveSonDaughterDetailToRedux(dispatch, partnerRkey , child_data)

      helper_health_history.saveHistoryDiseasesToRedux(dispatch, child_data.rkey, child_data.diseases)
      helper_health_history.saveHistoryGenesToRedux(dispatch, child_data.rkey, child_data.genetic_testing)

      // Crawl Lower Tree
      await populatePartnersFromAPI(dispatch, child_data.rkey, child_data)
    }
  } catch (error) {
    helpers.logger(error)
  }
}

const identify_father_and_mother = (spouse, partner) => {
  let father_id = 0;
  let mother_id = 0;
  if (spouse.gender !== null && spouse.gender.toLowerCase() !== 'u') {
    if (spouse.gender.toLowerCase() == 'm') {
      father_id = spouse.id;
      mother_id = partner.id;
    } else {
      father_id = partner.id;
      mother_id = spouse.id;
    }
  } else {
    father_id = partner.id;
    mother_id = spouse.id;
  }

  let sameSex = (spouse.gender && partner.gender) ?  spouse.gender.toLowerCase() == partner.gender.toLowerCase() : false

  if (sameSex){
    if (spouse.is_blood_related_to_proband){
      father_id = spouse.id
      mother_id = partner.id
    }
    else{
      father_id = partner.id
      mother_id = spouse.id
    }
  }

  return [ father_id, mother_id ]
}

const updateFamilyTree = async (redux_tree, proband_with_ancestor, fromPedigree) => {
  let people = fromPedigree ? Object.values(redux_tree.profile) : Object.values(redux_tree.profiles)
  for(let person of people){
    let partners = cloneDeep(person.partners)
    if((person.infertile || person.no_children) && partners.length == 0){
      // fake partner node
      let partner = createFakePartner(person, redux_tree)
      partners.push(partner)
    }
    for(let partner of partners){
      let fake_node_exists = people.find(node => (node.father_id == person.id && node.mother_id == partner.id) || (node.father_id == partner.id && node.mother_id == person.id))
      if((person.infertile || person.no_children || partner.infertile || partner.no_children) && !fake_node_exists){
        let [father_id, mother_id] = identify_father_and_mother(partner, person)

        // fake infertility/no children node
        let child = {}
        let temp_child_id = `fake_child-${father_id}-${mother_id}`;
        child.id = temp_child_id
        child.rkey = `apimem-${child.id}`;
        child.father_id = father_id
        child.mother_id = mother_id
        child.diseases = [];
        child.genetic_testing = [];
        child.gene_panels = [];
        child.family_id = proband_with_ancestor.family_id
        child.family_id_id = child.family_id;
        child.partners = [];
        child.relationship_ids = [];
        child.is_abortion_node = false;
        child.is_infertility_node = person.infertile || partner.infertile
        child.is_no_children_node = person.no_children || partner.no_children
        child.twin_id = null
        child.twin_id_id = null
        child.is_fake_node = true;

        updateFamilyTreeForFakeNodes(redux_tree, person, partner, child, fromPedigree);

      }
      else if(!person.infertile && !person.no_children && !person.is_fake_node){
        if(partner.is_fake_node){
          deleteFakeNodes(redux_tree, person, partner, fromPedigree, true);
        }
      }
      // else if(!partner.infertile && !partner.no_children && !person.infertile && !person.no_children){
      //     deleteFakeNodes(redux_tree, person, partner, fromPedigree, true);
      // }
      // else if((person.infertile || person.no_children) && !person.is_fake_node){
      //   if(!partner.is_fake_node){
      //     // deleteFakeNodes(redux_tree, person, partner, fromPedigree, false);
      //   }
      // }
    }
  }
}

const deleteFakeNodes = (redux_tree, person, partner, fromPedigree, include_partners) => {
  let profiles = fromPedigree ? redux_tree.profile : redux_tree.profiles
  let people = Object.values(profiles)
  let fake_children = people.filter(p => p.is_fake_node && ((p.mother_id == person.id && p.father_id == partner.id) || (p.mother_id == partner.id && p.father_id == person.id)))

  // console.log(people)
  // console.log(person)
  // console.log(partner)

  if(include_partners){
    if(partner.is_fake_node){
      delete profiles[partner.rkey]
      profiles[person.rkey].partners = profiles[person.rkey].partners.filter(ptnr => ptnr.id != partner.id)
      redux_tree.partners[person.rkey] = redux_tree.partners[person.rkey].filter(ptnr => ptnr.id != partner.id)
      redux_tree.partners[partner.rkey] = redux_tree.partners[partner.rkey].filter(ptnr => ptnr.id != person.id)
    }
  }

  for(let child of fake_children){
    delete profiles[child.rkey]
    profiles[person.rkey].children = profiles[person.rkey].children.filter(c => c.id != child.id)
    // if(partner.rkey in profiles){
    //   profiles[partner.rkey].children = profiles[partner.rkey].children.filter(c => c.id != child.id)
    // }
    redux_tree.sons_daughters[person.rkey] = redux_tree.sons_daughters[person.rkey].filter(sd => sd.id != child.id)
    redux_tree.sons_daughters[partner.rkey] = redux_tree.sons_daughters[partner.rkey].filter(sd => sd.id != child.id)
  }
}

const populateProbandFromAPI = async (dispatch, member_id) => {
  try {

    // let redux_tree = await refinery_api.get_redux_tree(member_id)
    let redux_tree = await refinery_api.get_redux_tree_deux(member_id, {"canvas": "1"})
    refinery_api.task_manager_test()

    let proband = redux_tree.proband
    proband.dial_code = redux_tree.dial_code
    proband.hash_key =  new Date().getTime()

    redux_tree.ancestor.mother.ancestry = redux_tree.ancestor.mother.ancestry.length > 0 ?
    redux_tree.ancestor.mother.ancestry.map(item => {
      return ({label: item.display_name, value: item.ancestry_id})}) : null;
    redux_tree.ancestor.father.ancestry = redux_tree.ancestor.father.ancestry.length > 0 ?
    redux_tree.ancestor.father.ancestry.map(item => {
      return ({label: item.display_name, value: item.ancestry_id})}) : null;
    let proband_with_ancestor = {...proband, ...redux_tree.ancestor}

    const objectArray = Object.entries(redux_tree.profiles);
    objectArray.forEach(([key, value]) => {
      if(value.twin_set === null){
        value.twin_type = null;
      }
    });

    redux_tree.profiles = Object.fromEntries(objectArray);

    updateFamilyTree(redux_tree, proband_with_ancestor, false)

    const patient_tree = {
      proband: proband_with_ancestor,
      siblings: redux_tree.siblings,
      uncles_aunts: redux_tree.uncles_aunts,
      partners: redux_tree.partners,
      sons_daughters: redux_tree.sons_daughters,
      profiles: redux_tree.profiles,
      diseases: redux_tree.diseases,
      genes: redux_tree.genetic_testings
    };

    dispatch(patient_actions.save_patient(proband_with_ancestor));

    dispatch(patient_actions.fill_siblings({siblings: redux_tree.siblings}))
    dispatch(patient_actions.fill_uncles_aunts({uncles_aunts: redux_tree.uncles_aunts}))
    dispatch(patient_actions.fill_partners({partners: redux_tree.partners}))
    dispatch(patient_actions.fill_sons_daughters({sons_daughters: redux_tree.sons_daughters}))
    dispatch(patient_actions.fill_profiles({profiles: redux_tree.profiles}))

    dispatch(patient_actions.fill_history_diseases({diseases: redux_tree.diseases}))
    dispatch(patient_actions.fill_history_genes({history_genes: redux_tree.genetic_testings}))

    dispatch(patient_actions.save_has_half_or_great_uncles_aunts({has_half_or_great_uncles_aunts: redux_tree.has_half_or_great_uncles_aunts}))

    // Below block will be removed soon
    /*
    // Create default data
    let proband = model.createPatient()
    // Fetch data from API and save to Redux
    let member = await family_api.get_member_memberid(member_id)
    let parents = await family_api.get_member_memberid_parents(member_id)
    let grandparents = await family_api.get_member_memberid_grandparents(member_id)

    // lookup if proband parents, maternal grandparents or paternal grandparents are blood related to each other meaning Consanguinity
    let parent_rel = await family_api.get_partner_relationship(parents.father.id, parents.mother.id);
    let paternal_grandparents_rel = await family_api.get_partner_relationship(grandparents.paternal_grandfather.id, grandparents.paternal_grandmother.id);
    let maternal_grandparents_rel = await family_api.get_partner_relationship(grandparents.maternal_grandfather.id, grandparents.maternal_grandmother.id);

    proband = Object.assign({}, proband, member)
    // Save to Redux
    proband.hash_key =  new Date().getTime()
    proband.father_id = parents.father.id
    proband.mother_id = parents.mother.id
    proband.is_parents_blood_related = parent_rel.is_parent_blood_related;
    proband.is_paternal_grandparents_blood_related = paternal_grandparents_rel.is_parent_blood_related;
    proband.is_maternal_grandparents_blood_related = maternal_grandparents_rel.is_parent_blood_related;

    let the_proband = fromJS(proband)
    the_proband = updateIn(the_proband, ['father'], val => val.merge(parents.father))
    the_proband = updateIn(the_proband, ['mother'], val => val.merge(parents.mother))
    the_proband = updateIn(the_proband, ['paternal_grandfather'], val => val.merge(grandparents.paternal_grandfather))
    the_proband = updateIn(the_proband, ['paternal_grandmother'], val => val.merge(grandparents.paternal_grandmother))
    the_proband = updateIn(the_proband, ['maternal_grandfather'], val => val.merge(grandparents.maternal_grandfather))
    the_proband = updateIn(the_proband, ['maternal_grandmother'], val => val.merge(grandparents.maternal_grandmother))
    the_proband = the_proband.toJS()

    dispatch(patient_actions.save_patient(the_proband));

    // Save patient profile to redux
    let profile = cloneDeep(proband)
    delete profile.mother
    delete profile.father
    delete profile.maternal_grandfather
    delete profile.maternal_grandmother
    delete profile.paternal_grandfather
    delete profile.paternal_grandmother
    saveProfileToRedux(dispatch, profile);

    populateProbandParents(dispatch, the_proband);

    // Crawl to lower tree
    // Populate Proband Partners
    await populatePartnersFromAPI(dispatch, 'proband', profile)

    helper_health_history.saveHistoryDiseasesToRedux(dispatch, profile.rkey, proband.diseases)
    helper_health_history.saveHistoryGenesToRedux(dispatch, profile.rkey, proband.genetic_testing)
    */



    /* Run risk as a promise */
    risk_api.post_check_risk(member_id).then(risk_criteria_result => {
      dispatch(patient_actions.save_risk_criteria(risk_criteria_result.result));
    }).catch(error => helpers.logger(error));

    // reset risk results when a new proband is loaded
    dispatch(patient_actions.reset_risk_results(default_risk_results));

    return patient_tree;

  } catch (error) {
    throw error
  }
}

const updateFamilyTreeForFakeNodes = (family_tree, person, partner, child, fromPedigree) => {
  let profiles = fromPedigree ? family_tree.profile : family_tree.profiles

  // family profiles for child and partner
  profiles[child.rkey] = child
  profiles[partner.rkey] = partner

  // profile children for person
  if (profiles[person.rkey].children) {
    if (!profiles[person.rkey].children.find(p => p.id == child.id)) {
      profiles[person.rkey].children.push(child)
    }
  }
  else {
    profiles[person.rkey].children = [child]
  }

  // profile children for partner
  if (profiles[partner.rkey].children) {
    if (!profiles[partner.rkey].children.find(p => p.id == child.id)) {
      profiles[partner.rkey].children.push(child)
    }
  }
  else {
    profiles[partner.rkey].children = [child]
  }

  // profile partners for person
  if (profiles[person.rkey].partners) {
    if (!profiles[person.rkey].partners.find(p => p.id == partner.id)) {
      profiles[person.rkey].partners.push(partner)
    }
  }
  else {
    profiles[person.rkey].partners = [partner]
  }

  // profile partners for partner
  if (profiles[partner.rkey].partners) {
    if (!profiles[partner.rkey].partners.find(p => p.id == person.id)) {
      profiles[partner.rkey].partners.push(person)
    }
  }
  else {
    profiles[partner.rkey].partners = [person]
  }

  // family tree sons daughters for person
  if (!(person.rkey in family_tree.sons_daughters)) {
    family_tree.sons_daughters[person.rkey] = [child]
  }
  else {
    if (!family_tree.sons_daughters[person.rkey].find(p => p.id == child.id)) {
      family_tree.sons_daughters[person.rkey].push(child)
    }
  }

  // family tree sons daughters for partner
  if (!(partner.rkey in family_tree.sons_daughters)) {
    family_tree.sons_daughters[partner.rkey] = [child]
  }
  else {
    if (!family_tree.sons_daughters[partner.rkey].find(p => p.id == child.id)) {
      family_tree.sons_daughters[partner.rkey].push(child)
    }
  }


  // family tree partners for person
  if (!(person.rkey in family_tree.partners)) {
    family_tree.partners[person.rkey] = [partner]
  }
  else {
    if (!family_tree.partners[person.rkey].find(p => p.id == partner.id)) {
      family_tree.partners[person.rkey].push(partner)
    }
  }

  // family tree partners for partner
  if (!(partner.rkey in family_tree.partners)) {
    family_tree.partners[partner.rkey] = [person]
  }
  else {
    if (!family_tree.partners[partner.rkey].find(p => p.id == person.id)) {
      family_tree.partners[partner.rkey].push(person)
    }
  }
}

const createFakePartner = (profile, family_tree) => {
  let partner = model.createPartner();

  partner.relationship_data.is_parent_blood_related = false
  partner.relationship_data.marital_status = 'other'

  partner.sons_count = 0;
  partner.daughters_count = 0;

  // since there is also one possible fake partner for each person, this should work
  let temp_partner_id = `fake_partner-${profile.id}`;
  let temp_relationship_id = `fake_relationship-${profile.id}`;

  partner.id = temp_partner_id

  let partner_gender = '';
  if(profile.gender !== null && profile.gender.toLowerCase() !== 'u'){
    partner_gender = (profile.gender.toLowerCase() == 'm' ? 'f' : 'm')
  }
  else{
    partner_gender = null;
  }

  let [father_id, mother_id] = identify_father_and_mother(profile, partner)

  partner.relationship_data.id = temp_relationship_id
  partner.relationship_data.rkey = `r-${temp_relationship_id}`;
  partner.gender = partner_gender;
  partner.relationship_data.blood_relation_type = ''
  partner.relationship_data.mother_id = mother_id;
  partner.relationship_data.father_id = father_id;
  partner.relationship_data.mother_id_id = mother_id;
  partner.relationship_data.father_id_id = father_id;
  partner.relationship_data.pregnancy = null;
  partner.rkey = `apimem-${partner.id}`;
  partner.diseases = [];
  partner.genetic_testing = [];
  partner.gene_panels = [];
  partner.family_id = profile.family_id
  partner.is_abortion_node = false;
  partner.is_blood_related_to_proband = false;
  partner.is_dead = false;
  partner.is_infertility_node = false;
  partner.is_no_children_node = false;
  partner.is_proband = false
  partner.infertile = profile.infertile
  partner.no_children = profile.no_children
  partner.family_id_id = partner.family_id;
  partner.mother_id = null;
  partner.father_id = null;
  partner.mother_id_id = null;
  partner.father_id_id = null;
  partner.partners = [profile];

  partner.relationship_ids = [partner.relationship_data]
  profile.relationship_ids.push(partner.relationship_data)

  partner.twin_id = null;
  partner.twin_id_id = null;
  partner.is_fake_node = true;
  return partner
}

const populateProbandParents = (dispatch, proband) => {

  // Profiles
  let father = proband.father
  father.father_id = proband.paternal_grandfather.id
  father.mother_id = proband.paternal_grandmother.id
  saveProfileToRedux(dispatch, father);
  helper_health_history.saveHistoryDiseasesToRedux(dispatch, proband.father.rkey, proband.father.diseases)
  helper_health_history.saveHistoryGenesToRedux(dispatch, proband.father.rkey, proband.father.genetic_testing)

  let mother = proband.mother
  mother.father_id = proband.maternal_grandfather.id
  mother.mother_id = proband.maternal_grandmother.id
  saveProfileToRedux(dispatch, mother);
  helper_health_history.saveHistoryDiseasesToRedux(dispatch, proband.mother.rkey, proband.mother.diseases)
  helper_health_history.saveHistoryGenesToRedux(dispatch, proband.mother.rkey, proband.mother.genetic_testing)

  saveProfileToRedux(dispatch, proband.paternal_grandfather);
  helper_health_history.saveHistoryDiseasesToRedux(dispatch, proband.paternal_grandfather.rkey, proband.paternal_grandfather.diseases)
  helper_health_history.saveHistoryGenesToRedux(dispatch, proband.paternal_grandfather.rkey, proband.paternal_grandfather.genetic_testing)

  saveProfileToRedux(dispatch, proband.paternal_grandmother);
  helper_health_history.saveHistoryDiseasesToRedux(dispatch, proband.paternal_grandmother.rkey, proband.paternal_grandmother.diseases)
  helper_health_history.saveHistoryGenesToRedux(dispatch, proband.paternal_grandmother.rkey, proband.paternal_grandmother.genetic_testing)

  saveProfileToRedux(dispatch, proband.maternal_grandfather);
  helper_health_history.saveHistoryDiseasesToRedux(dispatch, proband.maternal_grandfather.rkey, proband.maternal_grandfather.diseases)
  helper_health_history.saveHistoryGenesToRedux(dispatch, proband.maternal_grandfather.rkey, proband.maternal_grandfather.genetic_testing)

  saveProfileToRedux(dispatch, proband.maternal_grandmother);
  helper_health_history.saveHistoryDiseasesToRedux(dispatch, proband.maternal_grandmother.rkey, proband.maternal_grandmother.diseases)
  helper_health_history.saveHistoryGenesToRedux(dispatch, proband.maternal_grandmother.rkey, proband.maternal_grandmother.genetic_testing)

}

const resetFamilyRedux = (dispatch) => {

  dispatch(patient_actions.clear_patient())
  dispatch(patient_actions.clear_profiles())
  dispatch(patient_actions.clear_sons_daughters())
  dispatch(patient_actions.clear_siblings())
  dispatch(patient_actions.clear_uncles_aunts())
  dispatch(patient_actions.clear_partners())
  dispatch(patient_actions.clear_history_diseases())
  dispatch(patient_actions.clear_history_genes())
  dispatch(patient_actions.clear_risk_factors());

}

const savePatientToRedux = (dispatch, patient_detail) => {
    dispatch(patient_actions.save_patient(patient_detail))
}

const saveProfilesToRedux = (dispatch, profiles) => {
  for(var profile of profiles) {
    saveProfileToRedux(dispatch, profile)
  }
}

const saveProfileToRedux = (dispatch, data) => {
  // let payload = {
  //   rkey: data.rkey,
  //   firstName: data.firstName,
  //   lastName: data.lastName,
  //   gender: ('gender' in data ? data.gender : 'unknown')
  // }
  dispatch(patient_actions.save_profile(data))
}

const savePartnerToRedux = (dispatch, ownerRkey, partner_detail) => {
  let payload = { ownerRkey, partner_detail}
  dispatch(patient_actions.save_partner_detail(payload))
}

const saveSonsDaughtersToRedux = (dispatch, partnerRkey, sons_daughters) => {
  let payload = { partnerRkey, sons_daughters}
  dispatch(patient_actions.save_sons_daughters(payload))
}

const saveSonDaughterDetailToRedux = (dispatch, partnerRkey, son_daughter_detail) => {
  let payload = { partnerRkey, son_daughter_detail }
  dispatch(patient_actions.save_son_daughter_detail(payload))
}

const saveSiblingsToRedux = (dispatch, siblings) => {
  dispatch(patient_actions.save_siblings(siblings))
}

const saveSiblingsByGenderToRedux = (dispatch, gender, siblings) => {
  let payload = {
    gender, siblings
  }
  dispatch(patient_actions.save_siblings(payload))
}

const saveSiblingDetailToRedux = (dispatch, sibling_detail) => {
  let payload = { sibling_detail }
  dispatch(patient_actions.save_sibling_detail(payload))
}

const saveUnclesAuntsToRedux = (dispatch, parent_side='paternal', uncles_aunts) => {
  let payload = { parent_side, uncles_aunts }
  dispatch(patient_actions.save_uncles_aunts(payload))
}

const saveUnclesAuntsByGenderToRedux = (dispatch, parent_side='paternal', gender, uncles_aunts) => {
  let payload = { parent_side, gender, uncles_aunts }
  dispatch(patient_actions.save_uncles_aunts_by_gender(payload))
}

const saveUncleAuntDetailToRedux = (dispatch, parent_side='paternal', uncle_aunt_detail) => {
  let payload = { parent_side, uncle_aunt_detail }
  dispatch(patient_actions.save_uncle_aunt_detail(payload))
}

const deleteItem = (dispatch, rkey) => {

}

const deleteProfileFromRedux = (dispatch, profile_rkey=null) => {

  let payload = { profile_rkey }
  dispatch(patient_actions.delete_profile(payload))

}

const deletePartnerFromRedux = (dispatch, ownerRkey, partner_rkey=null) => {
  let payload = { ownerRkey, partner_rkey }
  dispatch(patient_actions.delete_partner(payload))

}

const clearPartnersFromRedux = (dispatch, ownerRkey=null) => {

  let payload = { ownerRkey }
  dispatch(patient_actions.clear_partners(payload))

}

const deleteSonDaughterFromRedux = (dispatch, partnerRkey, son_daughter_rkey=null) => {
  let payload = { partnerRkey, son_daughter_rkey }
  dispatch(patient_actions.delete_son_daughter(payload))
}

const deleteSiblingFromRedux = (dispatch, sibling_rkey) => {

  let payload = { sibling_rkey}
  dispatch(patient_actions.delete_sibling(payload))

}

const deleteUncleAuntFromRedux = (dispatch, parent_side, uncle_aunt_rkey) => {

  // delete partner
  let payload = { parent_side, uncle_aunt_rkey}
  dispatch(patient_actions.delete_uncle_aunt(payload))

}

const deleteHistoriesFromRedux = (dispatch, rkey) => {

}

const populatePartnersByCount = (dispatch, ownerRkey, total_count) => {
  var payload = { ownerRkey: ownerRkey, total_count }
  dispatch(patient_actions.populate_partner_by_count(payload))
}

const populateSiblingsByCount = async (dispatch,  gender, total_count, options= {}) => {

  try {
    var siblings = model.createSiblingsByCount(gender, total_count)

    for(var sibling of siblings) {
      var data = await helper_family_api.create_sibling(sibling, options.family_id, options.father_id, options.mother_id)
      let sibling_detail = Object.assign({}, sibling, data)
      sibling_detail.father_id = options.father_id
      sibling_detail.mother_id = options.mother_id
      saveSiblingDetailToRedux(dispatch, sibling_detail)
      saveProfileToRedux(dispatch, sibling_detail)
    }
    // let payload = { siblings }
    // dispatch(patient_actions.populate_siblings_by_count(payload))
    // saveProfilesToRedux(dispatch, siblings)

  } catch (error) {
    throw error
  }

}

const populateSiblingsFromAPI = async (dispatch, proband_id, father_id, mother_id) => {

  try {

    let siblings = await family_api.get_members_memberid_children(father_id, mother_id);
    siblings = siblings.filter(item => item.id != proband_id)

    for(var sibling of siblings) {
      var redux_data = model.createSibling(sibling.gender)
      let sibling_detail = Object.assign({}, redux_data, sibling)
      sibling_detail.father_id = father_id
      sibling_detail.mother_id = mother_id

      saveSiblingDetailToRedux(dispatch, sibling_detail)
      saveProfileToRedux(dispatch, sibling_detail)

      helper_health_history.saveHistoryDiseasesToRedux(dispatch, sibling_detail.rkey, sibling_detail.diseases)
      helper_health_history.saveHistoryGenesToRedux(dispatch, sibling_detail.rkey, sibling_detail.genetic_testing)

      //Crawl Lower Tree
      await populatePartnersFromAPI(dispatch, sibling_detail.rkey, sibling_detail)
    }

  } catch (error) {
    throw error
  }
}

const populateProbandParentOtherPartnersFromAPI = async (dispatch, parent_code='father', father_id, mother_id) => {
  try {

    if(father_id === null || mother_id === null) return

    let spouse_id = null;
    let exclude_id = null; //
    if(parent_code == 'father') {
      spouse_id = father_id
      exclude_id = mother_id
    } else {
      spouse_id = mother_id
      exclude_id = father_id
    }

    let ownerRkey = 'proband_' + parent_code

    // Clear redux before repopulating
    dispatch(patient_actions.delete_partner({ownerRkey}))

    // Fetch API
    let partners = await family_api.get_member_memberid_partners(spouse_id)
    for(var partner of partners) {
      if(partner.id === exclude_id) continue;
      var partner_from_model = model.createPartner()
      var partner_data = Object.assign({}, partner_from_model, partner)

      // Save to Redux
      // partner_data.rkey = 'member_' + partner_data.id
      savePartnerToRedux(dispatch, ownerRkey, partner_data)
      saveProfileToRedux(dispatch, partner_data)

      helper_health_history.saveHistoryDiseasesToRedux(dispatch, partner_data.rkey, partner_data.diseases)
      helper_health_history.saveHistoryGenesToRedux(dispatch, partner_data.rkey, partner_data.genetic_testing)

      if(parent_code == 'father') {
        await populateHalfSiblingsFromAPI(dispatch, father_id, partner.id);
      } else {
        await populateHalfSiblingsFromAPI(dispatch, partner.id, mother_id);
      }
    }
  } catch (error) {
    throw error
  }
}

const populateHalfSiblingsFromAPI = async (dispatch, father_id, mother_id) => {

  try {

    let siblings = await family_api.get_members_memberid_children(father_id, mother_id);
    for(var sibling of siblings) {
      var redux_data = model.createSibling(sibling.gender)
      let sibling_detail = Object.assign({}, redux_data, sibling)
      sibling_detail.father_id = father_id
      sibling_detail.mother_id = mother_id

      saveSiblingDetailToRedux(dispatch, sibling_detail)
      saveProfileToRedux(dispatch, sibling_detail)

      helper_health_history.saveHistoryDiseasesToRedux(dispatch, sibling_detail.rkey, sibling_detail.diseases)
      helper_health_history.saveHistoryGenesToRedux(dispatch, sibling_detail.rkey, sibling_detail.genetic_testing)


      //Crawl Lower Tree
      await populatePartnersFromAPI(dispatch, sibling_detail.rkey, sibling_detail)
    }

  } catch (error) {
    throw error
  }
}

const populateUnclesAuntsByCount = async (dispatch, parent_side, gender, total_count, options = {}) => {
  try {
    var uncles_aunts = model.createUnclesAuntsByCount(gender, total_count)

    for(var uncle_aunt of uncles_aunts) {
      var data = await helper_family_api.create_uncle_aunt(uncle_aunt, parent_side, options.family_id, options.father_id, options.mother_id)
      let uncle_aunt_detail = Object.assign({}, uncle_aunt, data)
      saveUncleAuntDetailToRedux(dispatch, parent_side, uncle_aunt_detail)
      saveProfileToRedux(dispatch, uncle_aunt_detail)
    }
    // let payload = { parent_side, uncles_aunts }
    // dispatch(patient_actions.populate_uncles_aunts_by_count(payload))
    // saveProfilesToRedux(dispatch, uncles_aunts)
  } catch (error) {
    throw error
  }
}

const populateUnclesAuntsFromAPI = async (dispatch, parent_side, proband_parent_id, father_id, mother_id) => {

  try {

    let uncles_aunts = await family_api.get_members_memberid_children(father_id, mother_id);
    uncles_aunts = uncles_aunts.filter(item => item.id != proband_parent_id)

    for(var uncle_aunt of uncles_aunts) {
      var redux_data = model.createUncleAunt(uncle_aunt.gender)
      let uncle_aunt_detail = Object.assign({}, redux_data, uncle_aunt)
      uncle_aunt_detail.father_id = father_id;
      uncle_aunt_detail.mother_id = mother_id;

      saveUncleAuntDetailToRedux(dispatch, parent_side, uncle_aunt_detail)
      saveProfileToRedux(dispatch, uncle_aunt_detail)

      helper_health_history.saveHistoryDiseasesToRedux(dispatch, uncle_aunt_detail.rkey, uncle_aunt_detail.diseases)
      helper_health_history.saveHistoryGenesToRedux(dispatch, uncle_aunt_detail.rkey, uncle_aunt_detail.genetic_testing)


      //Crawl Lower Tree
      await populatePartnersFromAPI(dispatch, uncle_aunt_detail.rkey, uncle_aunt_detail)

    }

  } catch (error) {
    throw error
  }

}


const getSonsDaughtersFromRedux = (tree, partnerRkey, gender = "ALL") => {
  if(partnerRkey in tree.sons_daughters) {
    let sons_daughters = tree.sons_daughters[partnerRkey]
    if(gender === 'ALL') {
      return Object.assign([],sons_daughters)
    } else {
      return Object.assign([],sons_daughters.filter(child => child.gender === gender))
    }
  }
  return []
}

const getPartnersFromRedux = (tree, ownerRkey) => {
  if(ownerRkey in tree.partners) {
    return Object.assign([], tree.partners[ownerRkey])
  }
  return []
}

const getSonDaughterParentPartnerRkey = (sons_daughters, son_daughter_rkey=null) => {
  for(var key in sons_daughters) {
    var child = sons_daughters[key].find(item => item.rkey == son_daughter_rkey)
    if(typeof(child) !== 'undefined') return key
  }
  return null;
}

const getPartnerOwnerRkey = (partners, partner_rkey=null) => {
  for(var key in partners) {
    var partner = partners[key].find(item => item.rkey == partner_rkey)
    if(typeof(partner) !== 'undefined') return key
  }
  return null;
}

const deletePartner = (tree, dispatch, ownerRkey, partner ) => {
  let partnerRkey = partner.rkey
  deleteAllChildren(tree, dispatch, partnerRkey)
  deleteProfileFromRedux(dispatch, partnerRkey)
  deletePartnerFromRedux(dispatch, ownerRkey, partnerRkey)

}

const deleteChildren = (tree, dispatch, partnerRkey, son_daughter_rkey) => {
    // delete partners of son and daughters
    deleteAllPartners(tree, dispatch, son_daughter_rkey)
    deleteProfileFromRedux(dispatch, son_daughter_rkey)
    deleteSonDaughterFromRedux(dispatch, partnerRkey, son_daughter_rkey)
}

const deleteAllChildren = (tree, dispatch, partnerRkey) => {

  let sons_daughters = getSonsDaughtersFromRedux(tree, partnerRkey)
  for(var son_daughter of sons_daughters){
    deleteChildren(tree, dispatch, partnerRkey, son_daughter.rkey)
  }
}

const deleteAllPartners = (tree, dispatch, ownerRkey) => {
  let partners = getPartnersFromRedux(tree, ownerRkey)
  for(var partner of partners) {
    deletePartner(tree, dispatch, ownerRkey, partner.rkey)
  }
}

const deleteUncleAunt = async (tree, dispatch, parentSide, uncleAunt) => {
  let uncleAuntRkey = uncleAunt.rkey
  deleteAllPartners(tree, dispatch, uncleAuntRkey)
  deleteProfileFromRedux(dispatch, uncleAuntRkey)
  deleteUncleAuntFromRedux(dispatch, parentSide, uncleAuntRkey)
}

const deleteSibling = async (dispatch, tree, sibling) => {
  let siblingRkey = sibling.rkey
  deleteAllPartners(tree, dispatch, siblingRkey)
  deleteProfileFromRedux(dispatch, siblingRkey)
  deleteSiblingFromRedux(dispatch, siblingRkey)
}

const deleteTopLevelParents = async (dispatch, person_rkey, partner_rkey, person_id, partner_id) => {
  deleteProfileFromRedux(dispatch, person_rkey)
  deleteProfileFromRedux(dispatch, partner_rkey)
  dispatch(patient_actions.delete_parent_ids_on_top_level_delete({person_id, partner_id}))

}

const getProfileFromRedux = (tree, profileRkey) => {
  if(tree.profile){
    if(profileRkey in tree.profile) return tree.profile[profileRkey]
  }
  else if(tree.profiles){
    if(profileRkey in tree.profiles) return tree.profiles[profileRkey]
  }


  return null;
  // return model.createPartner()
}

const fetchFamilyTreeHiddenDiseaseColors = async(dispatch, clinician_id, proband_id, organization_id) => {
  let payload = {clinician_id, proband_id, organization_id}
  let hidden_disease_colors = await family_api.get_hidden_disease_colors_post(payload)
  dispatch(patient_actions.save_hidden_disease_colors(hidden_disease_colors))
  return hidden_disease_colors;
}

const addToHiddenDiseaseColors = async(dispatch, payload) => {
  dispatch(patient_actions.add_to_hidden_disease_colors(payload))
}

const removeFromHiddenDiseaseColors = async(dispatch, payload) => {
  dispatch(patient_actions.remove_from_hidden_disease_colors(payload))
}

const fetchFamilyTreePedigreeNotes = async(dispatch, family_id) =>{

  let family_pedigree_notes = await family_api.get_family_pedigree_notes(family_id)
  dispatch(patient_actions.save_family_pedigree_notes(family_pedigree_notes))
}

export {
  resetFamilyRedux,
  savePatientToRedux,
  saveProfileToRedux,
  getProfileFromRedux,
  savePartnerToRedux,
  saveSonsDaughtersToRedux,
  saveSonDaughterDetailToRedux,
  saveSiblingsToRedux,
  saveSiblingsByGenderToRedux,
  saveSiblingDetailToRedux,
  saveUnclesAuntsToRedux,
  saveUnclesAuntsByGenderToRedux,
  saveUncleAuntDetailToRedux,
  populatePartnersByCount,
  populateSiblingsByCount,
  populateUnclesAuntsByCount,
  deleteItem,
  deleteProfileFromRedux,
  deletePartnerFromRedux,
  clearPartnersFromRedux,
  deleteSonDaughterFromRedux,
  deleteSiblingFromRedux,
  deleteUncleAuntFromRedux,
  getSonsDaughtersFromRedux,
  getPartnersFromRedux,
  deletePartner,
  deleteAllChildren,
  deleteAllPartners,
  deleteChildren,
  deleteUncleAunt,
  deleteSibling,
  deleteTopLevelParents,
  // populateSiblingsFromAPI,
  // populateUnclesAuntsFromAPI,
  populateProband,
  getSonDaughterParentPartnerRkey,
  getPartnerOwnerRkey,
  fetchFamilyTreeHiddenDiseaseColors,
  addToHiddenDiseaseColors,
  removeFromHiddenDiseaseColors,
  fetchFamilyTreePedigreeNotes,
  updateFamilyTree,
  identify_father_and_mother
}
