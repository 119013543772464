import React, { useMemo } from 'react';
import { Intersector, NamedLine, XY_Cords } from './ConnectionWatcher.ts';
import { useStore, getSmoothStepPath, useNodes } from '@xyflow/react';

import { createHook, createLine, createUUID, getEdgeParams } from './utils';

const FloatingStepEdge = (props) => {
  const id = props.id;
  const source = props.source;
  const target = props.target;
  const style = props.style;
  const markerEnd = props.markerEnd

  const nodes = useNodes()

  const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  // checking the target node we are assuming this is a BottomToChild edge
  let adopted_in = "none";
  if (targetNode.data.profile && targetNode.data.profile.adopted_in) {
    adopted_in = "5";
  }
  let ida = id + "_aa";
  let idb = id + "_bb";
  let intersection = [];
  let items = [];
  //  let selected_node = Object.values(props.data.datastore.nodes).find(node => node.selected);
  let connectionWatcher = props.data.getConnectionWatcher();
  let box = null;

  // if (selected_node) {
  //   box = [selected_node.x, selected_node.y, 150, 150];
  // }

  let sourceNodePos = [sx, sy];
  let targetNodePos = [tx, ty];

  if (props.data != null) {
    connectionWatcher.add_connections(sourceNode.id, targetNode.id, [ida, idb]);
    connectionWatcher.update_line_location(ida, sx, sy, tx, sy);
    intersection = connectionWatcher.update_line_location(idb, tx, sy, tx, ty);
    //  props.data.datastore.updateEdge({ "src": sourceNode.id, "target": targetNode.id, "type": "Child", "edgelist": [ida, idb] });
  }


  if (intersection.length == 0) {

    items.push(<line key={createUUID()} id={idb} x1={tx} y1={sy} x2={tx} y2={ty} style={style} strokeDasharray={adopted_in}></line>);
  }
  else {
    let hooksAndLines = props.data.datastore.splitVerticalLine(tx, sy, ty, intersection);
    let lines = hooksAndLines["lines"];
    for (let i = 0; i < lines.length; i++) {
      let line = createLine(lines[i], id, i, adopted_in, style);
      items.push(line);
    }
    let hooks = hooksAndLines["hooks"];
    for (let i = 0; i < hooks.length; i++) {
      let pathLine = createHook(hooks[i], id, i, markerEnd, style);
      items.push(pathLine);
    }
  }
  //node being dragged
  //source node
  //target node

  return (
    <g className="react-flow__connection">
      <line key={createUUID()} id={ida} x1={sx} y1={sy} x2={tx} y2={sy} style={style}></line>
      {items}
    </g>
  );
};

export default FloatingStepEdge;
