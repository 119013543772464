import React from "react";
import { Link } from "react-router-dom";

import SearchResultsBar from "../search/search-results-bar";
import * as helper_search from "../../helpers/helper-search";
import * as helpers from "../../helpers/index";
import route_helper from "../../route-helper";
import family_api from "../../api/family-api";
import Select, { components } from "react-select";
import ModalError from "../../components/modal-error";

export default class QueryToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.state = {
      currentActiveReport: {
        value: null,
        label: null,
      },
      readyToDelete: false,
      show_pedigree_limit_error_modal: false,
    };
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.selectReport = this.selectReport.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.readyToDeleteOn = this.readyToDeleteOn.bind(this);
    this.readyToDeleteOff = this.readyToDeleteOff.bind(this);
  }

  async handleSearchSubmit(query_version) {
    // update redux search with new search history
    helper_search.saveSearchFilter(
      this.props.dispatch,
      this.props.searchFilter
    );

    if(query_version === "new") {
      this.props.queryProbandsNew();
    } else {
      this.props.queryProbands();
    }
  }

  selectReport(report) {
    if (!this.state.readyToDelete && report) {
      this.setState({
        currentActiveReport: { value: report.value, label: report.label },
      });
      this.props.loadReport(report.value);
    } else if (!this.state.readyToDelete && report === null) {
      this.setState({ currentActiveReport: { value: null, label: null } });
      this.props.loadReport(null);
    }
  }

   async deleteReport(report, clearValue) {
    await this.props.openDeleteReportModal(report);
    if (
      this.state.readyToDelete &&
      this.state.currentActiveReport.value !== report.value
    ) {
      await this.selectRef.select.setValue(this.state.currentActiveReport);
    }
    if (this.state.currentActiveReport.value === null) {
      await this.selectRef.select.clearValue();
    }
    await this.readyToDeleteOff();
  }

  readyToDeleteOn() {
    this.setState({ readyToDelete: true });
  }

  readyToDeleteOff() {
    this.setState({ readyToDelete: false });
  }

  render() {
    let report_options = this.props.reports.map((report) => {
      return { value: report.id, label: report.title };
    });

    let enter_new_patient = <Link
      to={route_helper.patient.patient_root}
      className="btn btn-teal btn-md"
    >
      <i className="fa fa-plus text-white" />
      <span>Enter New Patient</span>
    </Link>

    if (this.props.user && this.props.user.max_number_of_pedigrees) {
      if (Number(this.props.number_of_pedigrees_on_org) >= Number(this.props.user.max_number_of_pedigrees)) {
        enter_new_patient = (
          <span className="btn btn-teal btn-md" onClick={() => this.setState({ show_pedigree_limit_error_modal: true })}>
            <i className="fa fa-plus text-white" />
            <span>Enter New Patient</span>
          </span>
        )
      }
    }

    return (
      <div className="search-criteria-area">
        <div className="query-search-button-input" >
          <div>
            <SearchResultsBar
              history={this.props.history}
              queryProbandsNew={() => this.props.queryProbandsNew()}
              queryProbands={() => this.props.queryProbands()}
              handleSearchSubmit={this.handleSearchSubmit}
            />
          </div>
          <div className="new-patient-button">
            {enter_new_patient}
          </div>

          <div className="search-bar report-dropdown">
            <Select
              ref={(ref) => {
                this.selectRef = ref;
              }}
              value={report_options.value}
              name="report"
              onChange={(item) => {
                this.selectReport(item);
              }}
              className="react-select-container save-report-dropdown"
              classNamePrefix="react-select"
              isClearable={true}
              placeholder=""
              deleteReport={async (report, clearValue) =>
                await this.deleteReport(report, clearValue)
              }
              readyToDeleteOn={() => this.readyToDeleteOn()}
              readyToDeleteOff={() => this.readyToDeleteOff()}
              options={report_options}
              components={{ Option: IconOption }}
            />
          </div>
          <div
            className="flex-right"
            style={{ justifyContent: "flex-start" }}
          >
            <button
              onClick={this.props.openReport}
              className="btn btn-teal btn-xs select-button"
              data-toggle="modal"
              data-target="#SaveReport"
            >
              <i style={{ paddingRight: "7px" }} className="fa fa-save"></i>Save
              Report
            </button>
          </div>
        </div>

        {this.state.show_pedigree_limit_error_modal && (
          <ModalError
            pedigree_limit_error={true}
            message="You have reached the limit of pedigrees for a free account. Please contact us to upgrade."
            onClose={() => this.setState({ show_pedigree_limit_error_modal: false })}
          />
        )}
      </div>
    );
  }
}

const IconOption = (props) => {
  const displayTrash = props.isFocused ? "block" : "none";
  return (
    <components.Option {...props}>
      {props.data.label}
      <a
        onClick={() =>
          props.selectProps.deleteReport(props.data, props.clearValue)
        }
      >
        <i
          onMouseEnter={() => props.selectProps.readyToDeleteOn()}
          onMouseLeave={() => props.selectProps.readyToDeleteOff()}
          style={{
            display: displayTrash,
            cursor: "pointer",
            float: "right",
            marginTop: "4px",
          }}
          className="fa fa-trash"
        ></i>
      </a>
    </components.Option>
  );
};
