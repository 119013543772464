import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResultFilterColumn from './result-filter-column'
import { fromJS, updateIn } from 'immutable'
import userAccountApi from '../../api/user-account-api';
import FilterCancer from './filter-cancer'
import FilterGene from './filter-gene'
import FilterReferral from './filter-referral'
import FilterMore from './filter-more'
import { createUUID } from '../react-flow-pedigree/utils';

import lodash, { cloneDeep } from 'lodash'
import { isEqual } from 'lodash';
import OutsideAlerter from './OutsideAlerter'

import SearchResultsBar from '../search/search-results-bar';

import AddFilter from './add-filter'

import json_columns from './columns.json'
import ControlSelectSurveys from '../control-select-surveys';

// let filterItemDisease = { andOr: 'and', cancerType: '', relationalOperator: '<=', age: '' }
let filterItemDisease = { fieldType: 'disease', andOr: 'and', cancerType: '', relationalOperator: 'any_age', age: '' }
let filterSetDisease = { andOr: 'and', items: [filterItemDisease] }

// let filterItemGene = { andOr: 'and', gene: '', result: 'any' }
let filterItemGene = { fieldType: 'genetic_testing', andOr: 'and', gene: '', result: 'any' , Variant: '' }
let filterSetGene = { andOr: 'and', items: [filterItemGene] }

// let filterItemReferral = { andOr: 'and', referral: '' }
let filterItemReferral = { fieldType: 'referral_criteria', andOr: 'and', referral: '' }
let filterSetReferral = { andOr: 'and', items: [filterItemReferral] }

let filter_more_init_state = {
  dob_operator: '=',
  dob_from: null,
  dob_to: null,
  age_operator: '=',
  age: null,
  age_to: null,
  // risk_operator: 'between',
  // risk: '',
  gender: null,
  clinician: null,
  status: null
};

class ResultFilter extends Component {
  constructor(props) {
    super(props);
    let currentHash = new Date().getTime()

    this.state = {
      open: false,
      columns: [],
      successMessage: false,
      search_text: '',
      hashCancer: currentHash + 1,
      hashGene: currentHash + 2,
      hashReferral: currentHash + 3,
      hashMore: currentHash + 4,
      hashAddFilter: currentHash + 5,
      currentFilterPanel: '', //cancer, gene, referral, more
      currentFilterPanelFilterSets: [],
      more_filters: filter_more_init_state,
      draggedItemIndex: null,
      copyMessage: false,
      includeUMLS: false,
      show_filters_dropdown: false,
      active_ordered_filters: this.props.active_ordered_filters, //
      genes: {},
      diseases: {},
      referrals: {},
      survey_options: []
    }

    this.handleOpenClick = this.handleOpenClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleOpenFilter = this.handleOpenFilter.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleClearFilterSets = this.handleClearFilterSets.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDrop = this.handledDrop.bind(this);
    this.copyText = this.copyText.bind(this);
    this.savePDF = this.savePDF.bind(this);
    this.isActive = this.isActive.bind(this);
    this.setCurrentFilterPanelFilterSet = this.setCurrentFilterPanelFilterSet.bind(this);
    this.handleOutsideClickThenQuery = this.handleOutsideClickThenQuery.bind(this);
    this.setFilterHashes = this.setFilterHashes.bind(this);
    this.saveDefaultColumns = this.saveDefaultColumns.bind(this);
    this.loadDefaultColumns = this.loadDefaultColumns.bind(this);
    this.handleIncludeUMLS = this.handleIncludeUMLS.bind(this);
    this.handleClickAddFilters = this.handleClickAddFilters.bind(this);
    this.handleClickAddFiltersOption = this.handleClickAddFiltersOption.bind(this);
    this.handleHideAllFilters = this.handleHideAllFilters.bind(this);
    this.setFilterStates = this.setFilterStates.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.buildSurveyColumns = this.buildSurveyColumns.bind(this);
    this.handleSurveyColumn = this.handleSurveyColumn.bind(this);
    this.surveyListCheck = this.surveyListCheck.bind(this); 
  }

  componentDidMount(){
    // temporary code for placeholder filter for famhis admin accounts page
    if (!this.props.famhis_admin){
      this.props.getSetFilterHashes(this.setFilterHashes);
      if(this.props.defaultColumns){
        this.loadDefaultColumns()
      }
      this.buildSurveyColumns()
    }
  }

  componentDidUpdate(){
    if(this.props.defaultColumns ){
      this.loadDefaultColumns()
    }
  }

  setFilterHashes() {
    let currentHash = new Date().getTime()
    this.setState({
      hashCancer: currentHash + 1,
      hashGene: currentHash + 2,
      hashReferral: currentHash + 3,
      hashMore: currentHash + 4,
      hashAddFilter: currentHash + 5
    });
  }

  onDragStart(ev) {
    this.setState({ draggedItemIndex: ev.target.id })
  }

  onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move'
  }

  setFilterStates(state){
    this.setState({
      active_ordered_filters: state.active_ordered_filters,
      more_filters: state.more_filters,
      diseases: state.diseases,
      genes: state.genes,
      referrals: state.referrals,
    })
  }

  handledDrop(event) {
    const droppedItemIndex = event.currentTarget.id - 1
    const draggedIndex = this.state.draggedItemIndex - 1
    const draggedItem = this.state.columns[draggedIndex]
    let i = 1
    let columns = this.state.columns

    columns = [
      ...columns.slice(0, draggedIndex),
      ...columns.slice(draggedIndex + 1)
    ]

    columns.splice(droppedItemIndex, 0, draggedItem)
    //update order value of each item
    columns.forEach((item) => {
      item.order = i
      i++
    })
    this.setState({ columns })
    let selected_columns = columns.filter(item => item.checked)
    this.props.onRearrangeColumn(selected_columns, columns)
  }

  handleOpenFilter(filterPanel) {
    this.setState({ currentFilterPanel: filterPanel })
    let currentFilterPanel = '';

    if(filterPanel !== ''){
      if(this.props.abortController){
        this.props.abortController.abort();
      }
    }

    if (this.state.currentFilterPanel === 'cancer') {
      currentFilterPanel = 'disease';
    }
    else if (this.state.currentFilterPanel === 'gene') {
      currentFilterPanel = 'gene'
    }
    else if (this.state.currentFilterPanel === 'referral') {
      currentFilterPanel = 'referral'
    }
    else if (this.state.currentFilterPanel === 'more') {
      currentFilterPanel = 'more_filters'
    }

    this.props.handleIsActiveChange(currentFilterPanel, this.isActive(currentFilterPanel));
    switch (currentFilterPanel) {
      case 'disease':
        this.props.changeDiseaseFilters(lodash.cloneDeep(this.state.currentFilterPanelFilterSets), this.state.includeUMLS ,this.isActive('disease'));
        break;
      case 'gene':
        this.props.changeGeneFilters(lodash.cloneDeep(this.state.currentFilterPanelFilterSets), this.isActive('gene'));
        break;
      case 'referral':
        this.props.changeReferralFilters(lodash.cloneDeep(this.state.currentFilterPanelFilterSets), this.isActive('referral'));
        break;
      case 'more_filters':
        this.props.changeMoreFilters(lodash.cloneDeep(this.state.more_filters), this.isActive('more_filters'));
        break;
      default:
        break;
    }
  }

  handleClearFilter() {
    this.setState({ currentFilterPanel: '' });
    this.setState({ currentFilterPanelFilterSets: [] })
    this.handleHideAllFilters();
    this.resetFilters();
    this.props.onClearFilters()
    this.setFilterHashes();
  }

  handleClearFilterSets() {
    this.setState({ currentFilterPanel: '' });
    this.setState({ currentFilterPanelFilterSets: [] })
    this.handleHideAllFilters();
    this.resetFilters();
    this.setFilterHashes();
  }

  handleOpenClick() {
    this.setState({ open: !this.state.open })
  }

  setCurrentFilterPanelFilterSet(filterset) {
    this.setState({ currentFilterPanelFilterSets: filterset })
  }

  setMoreFilterState(more_filters) {
    this.setState({ more_filters: more_filters })
  }

  isActive(filter_name) {
    let filterSet = this.state.currentFilterPanelFilterSets
    if (filter_name === 'disease') {
      filterSet = filterSetDisease
    }
    else if (filter_name === 'gene') {
      filterSet = filterSetGene
    }
    else if (filter_name === 'referral') {
      filterSet = filterSetReferral
    }
    else if (filter_name === 'more_filters') {
      let active = lodash.isEqual(this.state.more_filters, filter_more_init_state) === false;
      let dateRegex = '^(((0?[1-9]|1[012])/(0?[1-9]|1\\d|2[0-8])|(0?[13456789]|1[012])/(29|30)|(0?[13578]|1[02])/31)/(19|[2-9]\\d)\\d{2}|0?2/29/((19|[2-9]\\d)(0[48]|[2468][048]|[13579][26])|(([2468][048]|[3579][26])00)))$'
      let ageRegex = '^\\d+$'
      let dateRegexF = new RegExp(dateRegex)
      let ageRegexF = new RegExp(ageRegex)

      let datesAreValid = this.state.more_filters.dob_operator === 'between' ? dateRegexF.test(String(this.state.more_filters.dob_from)) && dateRegexF.test(String(this.state.more_filters.dob_to)) : dateRegexF.test(String(this.state.more_filters.dob_from))
      let agesAreValid = this.state.more_filters.age_operator === 'between' ? ageRegexF.test(String(this.state.more_filters.age)) && ageRegexF.test(String(this.state.more_filters.age_to)) : ageRegexF.test(String(this.state.more_filters.age))
      let genderIsValid = this.state.more_filters.gender === 'm' || this.state.more_filters.gender === 'f' || this.state.more_filters.gender === 'unknown'

      if (active && (datesAreValid || (agesAreValid && Number(this.state.more_filters.age) < 130) || genderIsValid) || this.state.more_filters.clinician || this.state.more_filters.status) return true;

      return false;
    }
    let clean = [filterSet]
    let filterSets = this.state.currentFilterPanelFilterSets
    return lodash.isEqual(clean, filterSets) === false;
  }

  handleOutsideClickThenQuery() {
    let currentFilterPanel = '';

    if (this.state.currentFilterPanel === 'cancer') {
      currentFilterPanel = 'disease';
    }
    else if (this.state.currentFilterPanel === 'gene') {
      currentFilterPanel = 'gene'
    }
    else if (this.state.currentFilterPanel === 'referral') {
      currentFilterPanel = 'referral'
    }
    else if (this.state.currentFilterPanel === 'more') {
      currentFilterPanel = 'more_filters'
    }
    if (this.state.currentFilterPanel !== '') {
      this.handleOpenFilter('')
      this.props.handleIsActiveChange(currentFilterPanel, this.isActive(currentFilterPanel));
      switch (currentFilterPanel) {
        case 'disease':
          this.props.changeDiseaseFilters(lodash.cloneDeep(this.state.currentFilterPanelFilterSets), this.state.includeUMLS, this.isActive('disease'));
          break;
        case 'gene':
          this.props.changeGeneFilters(lodash.cloneDeep(this.state.currentFilterPanelFilterSets), this.isActive('gene'));
          break;
        case 'referral':
          this.props.changeReferralFilters(lodash.cloneDeep(this.state.currentFilterPanelFilterSets), this.isActive('referral'));
          break;
        case 'more_filters':
          this.props.changeMoreFilters(lodash.cloneDeep(this.state.more_filters), this.isActive('more_filters'));
          break;
        default:
          break;
      }
    }
  }
  handleIncludeUMLS(checked){
    this.setState({ includeUMLS: checked })
  }

  handleColumnChecked(checked, index) {
    let columns = cloneDeep(this.state.columns)
    let checked_columns = columns.filter(item => item.checked)

    // Cannot unchecked all columns
    if (checked === false && checked_columns.length == 1) return

    columns = fromJS(columns)
    columns = updateIn(columns, [index, 'checked'], _ => checked)
    columns = columns.toJS()
    this.setState({ columns })

    checked_columns = columns.filter(item => item.checked)
    this.props.onEditColumn(checked_columns, columns)
  }

  onChange(event) {
    this.setState({ search_text: event.currentTarget.value })
  }

  downloadCSV(event) {
    this.props.downloadCSV();
  }

  copyText(event) {
    this.setState({copyMessage: true})
    this.props.onCopy();
    setTimeout(() => {
      this.setState({copyMessage: false})
     }, 2000);
  }

  savePDF() {
    this.props.savePDF();
  }

  loadDefaultColumns(){
    if(this.state.columns !== undefined && (isEqual(this.props.defaultColumns, this.state.columns) == false)){
      this.setState({columns: this.props.defaultColumns})
    }
  }


  async saveDefaultColumns(){
    try{
      this.setState({loading: true})
      let finalColumns = []
      this.state.columns.forEach((item) => {
        if(item.isSurveyColumn && !item.checked)return
        else finalColumns.push(item)
      })
      let payload ={
        columns: finalColumns,
        user_id: this.props.user.user_id
      }
      await userAccountApi.save_default_columns(payload, this.props.user.clinician_id)
      this.setState({successMessage: true})
    }catch(err){
      console.log(err)
    }finally{
      this.setState({loading: false})
      setTimeout(() => {
        this.setState({successMessage: false})
       }, 3000);
      }
  }

  handleClickAddFilters(){
    this.setState({ show_filters_dropdown: !this.state.show_filters_dropdown })
  }

  handleHideAllFilters(){
    this.props.setActiveOrderedFilters([])
    this.setState({ active_ordered_filters: [] })
  }

  handleClickAddFiltersOption(e, option){
    if(e){
      e.preventDefault();
    }
    let active_ordered_filters = this.state.active_ordered_filters

    if(!active_ordered_filters.includes(option)){
      active_ordered_filters.push(option)
      //if only manually selected to add the filter, open that filter, otherwise do nothing
      if(e){
        if(option == 'disease'){
          this.setState({currentFilterPanel: 'cancer'})
        }
        else if(option == 'genetic_testing'){
          this.setState({currentFilterPanel: 'gene'})
        }
        else if(option == 'referral_criteria'){
          this.setState({currentFilterPanel: 'referral'})
        }
        else if(option == 'more'){
          this.setState({currentFilterPanel: 'more'})
        }
      }
    }
    else{
      const index = active_ordered_filters.indexOf(option);
      if (index > -1) {
        active_ordered_filters.splice(index, 1);
        if(option == 'disease'){
          this.props.changeDiseaseFilters(lodash.cloneDeep([]), this.state.includeUMLS, this.isActive('disease'));
        }
        else if(option == 'genetic_testing'){
          this.props.changeGeneFilters(lodash.cloneDeep([]), this.isActive('gene'));
        }
        else if(option == 'referral_criteria'){
          this.props.changeReferralFilters(lodash.cloneDeep([]), this.isActive('referral'));
        }
        else if(option == 'more'){
          this.props.changeMoreFilters(lodash.cloneDeep(filter_more_init_state), this.isActive('more_filters'));
        }
      }
    }

    this.props.setActiveOrderedFilters(active_ordered_filters)
    this.setState({ active_ordered_filters })
  }

  renderActiveFilters(){
    let active_ordered_filters = this.state.active_ordered_filters

    return active_ordered_filters.map(filter => {
      if(filter == 'disease'){
        return <FilterCancer
          ref={this.props.diseaseFilterRef}
          key={this.state.hashCancer}
          open={this.state.currentFilterPanel == 'cancer'}
          onOpen={(panel) => this.handleOpenFilter(panel)}
          handleIncludeUMLS={(includeUmls) => this.handleIncludeUMLS(includeUmls)}
          setCurrentFilterPanelFilterSet={(filterset) => this.setCurrentFilterPanelFilterSet(filterset)}
          changeDiseaseFilters={(disease_filters, includeUMLS, isActive) => this.props.changeDiseaseFilters(disease_filters, includeUMLS, isActive)}
          handleIsActiveChange={(filter_name, isActive) => this.props.handleIsActiveChange(filter_name, isActive)}
          diseases={this.state.diseases}
        />
      }
      else if(filter == 'genetic_testing'){
        return <FilterGene
          key={this.state.hashGene}
          ref={this.props.geneTestingFilterRef}
          open={this.state.currentFilterPanel == 'gene'}
          onOpen={(panel) => this.handleOpenFilter(panel)}
          setCurrentFilterPanelFilterSet={(filterset) => this.setCurrentFilterPanelFilterSet(filterset)}
          changeGeneFilters={(gene_filters, isActive) => this.props.changeGeneFilters(gene_filters, isActive)}
          handleIsActiveChange={(filter_name, isActive) => this.props.handleIsActiveChange(filter_name, isActive)}
          genes={this.state.genes}
        />
      }
      else if(filter == 'referral_criteria'){
        return <FilterReferral
          key={this.state.hashReferral}
          ref={this.props.referralFilterRef}
          open={this.state.currentFilterPanel == 'referral'}
          onOpen={(panel) => this.handleOpenFilter(panel)}
          setCurrentFilterPanelFilterSet={(filterset) => this.setCurrentFilterPanelFilterSet(filterset)}
          changeReferralFilters={(referral_filters, isActive) => this.props.changeReferralFilters(referral_filters, isActive)}
          handleIsActiveChange={(filter_name, isActive) => this.props.handleIsActiveChange(filter_name, isActive)}
          referrals={this.state.referrals}
        />
      }
      else if(filter == 'more'){
        return <FilterMore
          key={this.state.hashMore}
          ref={this.props.moreFilterRef}
          open={this.state.currentFilterPanel == 'more'}
          onOpen={(panel) => this.handleOpenFilter(panel)}
          setMoreFilterState={(more_filters) => this.setMoreFilterState(more_filters)}
          changeMoreFilters={(more_filters, isActive) => this.props.changeMoreFilters(more_filters, isActive)}
          handleIsActiveChange={(filter_name, isActive) => this.props.handleIsActiveChange(filter_name, isActive)}
          clinicians={this.props.org_clinicians}
          more_filters={this.state.more_filters}
        />
      }
    })
  }

  resetFilters(){
    this.setState({
      more_filters: filter_more_init_state,
      diseases: {},
      genes: {},
      referrals: {}
    })
  }

  async buildSurveyColumns(){
      let surveys = this.props.static_list.surveys
      let survey_list = []
      if (surveys) {
        for (let survey of surveys) {
  
          let fmt_category_name = survey.name.replace("-", " ").replace("_", " ").toUpperCase()
  
          let cat_options = [];
          for(let survey_category of survey.survey_categories){
            let options = [];
  
            for(let item of survey_category.question_workflows){
              let label = item.master_question.notification_label;
              if(!label){
                label = item.master_question.question_label;
              }
              if(!label){
                label = item.master_question.question_uuid.split('_').join(' ');
                label = label.charAt(0).toUpperCase() + label.slice(1);
              }
              if(item.master_question.type == 'label'){
                continue;
              }
              options.push({
                value: item.master_question.question_uuid,
                label: label,
                details: item,
                survey_name: survey.name
              })
            }
            cat_options = cat_options.concat(options)
          }
  
          survey_list.push({
            label: fmt_category_name,
            options: cat_options
          });
        }
  
        if (survey_list.length > 0) {
          this.setState({ survey_options: survey_list })
        }
      }
    }
  handleSurveyColumn(item){
    let columnCopy = cloneDeep(this.state.columns)
    let exists = columnCopy.some(column => column.value === item.value)
    if(exists) return
    let length = columnCopy.length
    columnCopy.push({ label: item.label, value: item.value, order: length + 1, checked: true, isSurveyColumn: true})

    this.setState({ columns: columnCopy })
    let checked_columns = columnCopy.filter(item => item.checked)

    this.props.onEditColumn(checked_columns, columnCopy)
  }
  surveyListCheck(){
    //If surveys arent added to static list by the time 
    //this component is mounted, then load them
  
    if(this.state.survey_options.length === 0) this.buildSurveyColumns()
  }

  render() {

    let show_filters_dropdown = this.state.show_filters_dropdown ? 'open' : '';
    let active_filters = this.renderActiveFilters();

    let advancedBtnClass = this.props.advanced_filters_results.status == 'Completed' && this.props.filter_class == 'advanced' ? 'btn-purple' : 'btn-light-outline';

    let progress_percentage = this.props.advanced_filters_results.status == 'Running' ? this.props.advanced_filters_results.amount_done : 0

    const refresh_button_jsx = this.props.advanced_filters_results.status == 'Running' ? null : (<button onClick={() => {
      if(this.props.advanced_filters_results.status == 'Completed' || this.props.advanced_filters_results.status == 'Running'){
        if(this.props.filter_class == 'standard'){
          this.props.queryProbandsNew();
        }
        else{
          this.props.runAdvancedQuery();
        }
      }

    }} className="btn btn-light btn-xs"><i className="fa fa-refresh"></i> Refresh</button>);

    let advanced_filters_results_records_count = this.props.advanced_filters_results.size
    let survey_options = this.state.survey_options ? this.state.survey_options : []

    return (
      <React.Fragment>
        <div key={this.props.resultListState}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{marginLeft: '1%', marginBottom: '1%'}}><h5>Filter by:</h5></div>
          <div style={{ display: 'flex', flexDirection: 'row', marginRight: '4%', alignItems: 'center'}}>
            {this.props.advanced_filters_results.status == 'Running' && <div className="progress" style={{ width: '100px', height: '5px', marginRight: '5px', marginTop: '10px' }}>
              <div className="progress-bar" role="progressbar" style={{ width: `${progress_percentage}%`, backgroundColor: '#65D1B9' }} aria-valuenow={`${progress_percentage}`} aria-valuemin="0" aria-valuemax="100"></div>
            </div>}
            <div style={{ fontSize: 12}}><p>{this.props.advanced_filters_results.status == 'Completed' || this.props.advanced_filters_results.status == 'Running' ? advanced_filters_results_records_count : this.props.recordsCount} records found</p></div>
          </div>
        </div>

        <div className="query-filter padding--none" style={{marginLeft: '1%'}}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className="row">
              <OutsideAlerter action={() => this.handleOutsideClickThenQuery()}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                  {active_filters}

                  {/* add filters */}
                  {this.props.show_add_filters &&
                    <AddFilter
                      show_filters_dropdown={show_filters_dropdown}
                      handleClickAddFilters={this.handleClickAddFilters}
                      handleClickAddFiltersOption={this.handleClickAddFiltersOption}
                      active_ordered_filters={this.state.active_ordered_filters}
                    />
                  }

                  {/* advanced filters */}
                  <button
                    id="advanced-filter-btn"
                    onClick={() => this.props.setShowAdvancedFilters(true)}
                    className={"btn btn-xs " + advancedBtnClass}> Advanced</button>

                  <button
                    onClick={this.handleClearFilter}
                    className="btn btn-light btn-xs"><i className="fa fa-times-circle"></i> Clear Filters</button>

                  {refresh_button_jsx}
                </div>
              </OutsideAlerter>
            </div>


            <div className="table-filter padding--none" style={{marginRight: '10%'}}>
              <div className="form-inline">
                <div className="row">
                  <div className="col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '150%' }}>
                      <div className="form-group popover-query-wrapper">
                        <button
                          onClick={this.handleOpenClick}
                          className="btn btn-default btn-xs no-margin-right btn-default__width--inherit" ><span className="sr-only">Column Settings</span> <i className="fa fa-cog no-margin"></i> Edit Columns</button>

                        <div id="colPop"
                          style={{ display: (this.state.open ? 'block' : 'none') }}
                          className="popover popover-default popover-custom popover-x kv-popover-active in bottom popover-query">

                          <div className="arrow popover-query__arrow"></div>
                          <h5 className="popover-header popover-title">
                            <span
                              onClick={this.handleOpenClick}
                              className="close pull-right min-width--inherit" >&times;</span>Column Settings
                                {this.state.successMessage &&(
                                  <i className='column-success'>Saved!</i>
                                )}
                                <span className='i-tooltip-column'>
                                  <a onClick={this.saveDefaultColumns} className='column-save min-width--inherit'>
                                    <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                                  </a>
                                <span className="i-tooltiptext-column">
                                  Save as default
                                </span>
                              </span>
                          </h5>
                          <div className="popover-body popover-content">
                            <ul>
                              {this.state.columns.map((item, index) =>
                                <ResultFilterColumn
                                  key={createUUID()}
                                  item={item}
                                  onChecked={(checked) => this.handleColumnChecked(checked, index)}
                                  onDragOver={(e) => this.onDragOver(e)}
                                  onDrag={(e) => this.onDragStart(e)}
                                  onDrop={(e) => this.handledDrop(e)} 
                                  />
                                )}
                            </ul>
                            <ul>
                            <ControlSelectSurveys
                              // inputProps=}
                              onClick={this.surveyListCheck()}
                              options={
                                [...survey_options]}
                              onChange={(item) => {
                                this.handleSurveyColumn(item)
                              }}
                              // value={survey_question}
                              placeholder={"Survey Columns.."}
                              value={"Survey Columns.."}
                            />
                            </ul>
                          </div>
                        </div>

                      </div>

                      <div className="form-group no-margin-right">
                        <div className="btn-group btn-group-xs" role="group" aria-label="..." style={{ display: 'flex', flexDirection: 'row' }}>
                          <button disabled={this.props.disableDocs} type="button" className="btn btn-default" onClick={this.copyText}>Copy</button>
                            {this.state.copyMessage &&(
                              <div id="copyPop" className="popover popover-wide popover-x popover-default popover-custom kv-popover-active in bottom popover-query">
                                <div className="arrow popover-query__arrow"></div>
                                  <h5 className="popover-header popover-title" style={{textTransform: 'none'}}> Copied to clipboard</h5>
                              </div>
                            )}
                          <button disabled={this.props.disableDocs} type="button" className="btn btn-default" onClick={this.downloadCSV}>CSV</button>
                          <button disabled={this.props.disableDocs} type="button" className="btn btn-default" onClick={this.savePDF}>PDF</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div>
        </div>
      </React.Fragment>
    )
  }
}

const redux_state = state => ({
  user: state.session.user,
  searchFilter: state.search.searchFilter,
  static_list: state.static_list,
  org_clinicians: state.session.user.org_clinicians
});

export default connect(
  redux_state, null, null, { forwardRef: true }
)( ResultFilter);
