import React, { Component } from "react";

import LabelField from './label-field';
import i18n from 'i18next';

class NumberField extends Component {
  constructor(props) {
    super(props);

    this.state = {value: ''};

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.answer) {
      this.setState({ value: this.props.answer.answer });
    }
  }

  onChange(event) {
    this.setState({ value: event.currentTarget.value });
  }

  onBlur(event) {
    const answers = (this.props.answer) ? [this.props.answer.answer] : ["", null, undefined];
    if ( !answers.includes(event.currentTarget.value) ) {
      const payload = {
        field_type: this.props.masterQuestion.type,
        master_question_id: this.props.masterQuestion.id,
        master_question_choice_id: null,
        answer: event.currentTarget.value
      };
      
      this.props.saveCompletedSurveyAnswer(payload);
    }
  }

  render() {
    const translation_key = this.props.masterQuestion.translation_key + ".placeholder";
    let question_placeholder_translation = i18n.t(translation_key);
    if (question_placeholder_translation === translation_key) {
      question_placeholder_translation = this.props.masterQuestion.place_holder_text;
    }
    const placeholder = (this.props.masterQuestion.place_holder_text) ? question_placeholder_translation : "type here";

    let column_one = null;
    let column_two = null;
    const field = (
      <input
        name={this.props.masterQuestion.question_uuid}
        id={this.props.masterQuestion.question_uuid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        value={this.state.value}
        type="text"
        className="form-control normal-input-text"
        placeholder={placeholder}
      />
    );

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <LabelField
          masterQuestion={this.props.masterQuestion}
        />
      );

    }

    column_two = field;

    return (
      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            {column_one}
          </div>

          <div className="col-md-6">
            {column_two}
          </div>

        </div>
      </div>
    );

  }
}

export default NumberField;
