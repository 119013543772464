import React, { Component } from 'react';

import ActivityIndicator from "../activity-indicator";
import ErrorSummary from "../error-summary";
import api_auth from '../../api/authentication';
import helpers from '../../helpers/index';
import LinkExpired from './link-expired'
import * as action_auth from '../../store/session/actions';

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: '',
      new_password_confirmation: '',
      agree_to_terms_and_conditions: false,
      loading: false,
      errorMessages: []
    };
    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.goToLogIn = this.goToLogIn.bind(this);
  }

  async componentDidMount() {
    this.mounted = true;
  }
  async componentWillUnmount() {
    this.mounted = false;
  }

  handleChange(event) {
    const value = event.target.value;
    const name  = event.target.name;
    this.setState({ [name]: value });
  }

  handleCheckboxChange(event) {
    const name  = event.target.name;
    const current_state = this.state[name]
    this.setState({ [name]: !current_state });
  }

  validate() {
    let options = {
      new_password: {
        labelName: 'Password',
        validations: { required: true }
      },
      new_password_confirmation: {
        labelName: 'Password Confirmation',
        validations: { required: true }
      }
    };

    let validations = helpers.validateInputs(this.state, options);
    this.setState({ errorMessages: validations.summary });

    return validations.isValid;

  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      this.setState({loading: true, errorMessages: []});

      let isValid = this.validate();
      if(!isValid) return;

      await api_auth.update_clinician_password(this.props.uid64, this.props.token, this.state.new_password, this.state.new_password_confirmation, this.props.initialCreation, this.state.agree_to_terms_and_conditions);
      this.props.onChangePasswordSuccess();

    } catch (error) {
      let parsedError = api_auth.parse_error_message(error);
      let capitalizedMessage = []
      let errorMessages = parsedError.message.split('. ')

      if(errorMessages.length > 1){
      capitalizedMessage = errorMessages.map((txt)=>{
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase().concat('. ');
      })
      }else{
        capitalizedMessage = parsedError.message
      }
      this.setState({errorMessages: [capitalizedMessage]});
      this.props.onChangePasswordFail();

    } finally {

      if(this.mounted) {
        this.setState({ loading: false });
      }

    }

  }

  handleTermsAndConditionsClick(event){
    event.preventDefault();
    let terms_and_conditions_link = String(process.env.REACT_APP_ROOT_API_URL).substring(0, process.env.REACT_APP_ROOT_API_URL.length - 2) + 'license_agreement/'
    window.open(terms_and_conditions_link, '_blank');
  }

  goToLogIn(event){
    event.preventDefault();
    window.location.href = '/';
  }

  render() {
    let form_text = this.props.initialCreation ? 'Set Password' : 'Change Password';
    let reset_type = this.props.initialCreation ? 'Create Password' : 'Reset Password';

    let disabled_due_to_agreement = false;
    if(this.props.initialCreation){
      if(!this.state.agree_to_terms_and_conditions){
        if(this.props.org_type == 'free' || this.props.org_type == 'trial_org' || this.props.org_type == 'student'){
          disabled_due_to_agreement = true;
        }
        else{
          disabled_due_to_agreement = false;
        }
      }
    }
    let disable_submit_button = this.props.disabled || disabled_due_to_agreement;

    return (
      <React.Fragment>
        {!this.props.password_already_set && (
          <>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                  <div className="form-group">
                      <p style={{ fontSize: '17px' }}>
                          <b>{reset_type}</b>
                      </p>
                      <p>
                          Minimum of 12 characters, one uppercase letter,
                          one lowercase letter, one number, and one special character.
                      </p>
                  </div>
                  <input type="password"
                      className="form-control input-lg"
                      placeholder="Password"
                      required=""
                      id="new_password"
                      name="new_password"
                      onChange={this.handleChange} />
              </div>
              <div className="form-group">
                  <input type="password"
                      className="form-control input-lg"
                      placeholder="Password Confirmation"
                      required=""
                      id="new_password_confirmation"
                      name="new_password_confirmation"
                      onChange={this.handleChange} />
              </div>
              {(this.props.org_type == 'free' || this.props.org_type == 'trial_org' || this.props.org_type == 'student') && (<div className="form-group">
                  <div className="checkbox">
                      <label className='pw-terms-and-conditions'>
                          <input type="checkbox" name="agree_to_terms_and_conditions" id="pw-checkbox" onChange={this.handleCheckboxChange} checked={this.state.agree_to_terms_and_conditions} /> By continuing, I agree to the Famgenix <button className="btn-link" id="terms_and_conditions_link" onClick={this.handleTermsAndConditionsClick}>Terms and Conditions</button>.
                      </label>
                  </div>
              </div>)}

              <div className="form-group">
                  <button type="submit" className="btn btn-teal btn-block btn-lg no-margin-top" disabled={disable_submit_button}>{form_text}</button>
              </div>
            </form>
            <ErrorSummary errorMessages={this.state.errorMessages} /><ActivityIndicator loading={this.state.loading} />
          </>
        )}
        {this.props.password_already_set && (
          <>
            <div className="form-group">
              <p style={{fontSize: '17px'}}>
                  A password has already been created for this account.
              </p>
            </div>
          
            <div className="form-group">
                <button type="submit" className="btn btn-teal btn-block btn-lg no-margin-top" onClick={this.goToLogIn}>Go to Log In</button>
            </div>
          </>
        )}

      </React.Fragment>

    );
  }
}

export default PasswordResetForm;
