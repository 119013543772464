import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Dropdown from 'react-dropdown';
import surveyQuestionTypes from './surveyQuestionTypes.json';

type ModalProps = {
  onClose: () => void;
  onSave: (action: string, question: any) => void;
  modalTitle: string;
  actionTaken: string;
  activeSurvey: any;
};

type surveyQuestion = {
  question_uuid: string;
  question_label: string;
  type: string;
  place_holder_text: string;
  notification_label: string;
  translation_key: string;
  question_label_markdown: string;
};

interface State {
  surveyQuestionTypes: any[];
  surveyQuestion: surveyQuestion;
  action: string;
  surveyQestionOptions: surveyQuestionOption[];
  dragIndex?: number;
}

interface surveyQuestionOption {
  value: string;
  label: string;
  order: number;
  [key: string]: any;
}

class EditAddQuestionModal extends Component<ModalProps, State> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      surveyQuestionTypes: surveyQuestionTypes, // Ensure this is populated from your file
      surveyQuestion: {
        question_uuid: '',
        question_label: '',
        type: '',
        place_holder_text: '',
        notification_label: '',
        translation_key: '',
        question_label_markdown: '',
      },
      surveyQestionOptions: [],
      action: '',
      dragIndex: undefined,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.addQuestionOption = this.addQuestionOption.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  async componentDidMount() {
    console.log(this.props.activeSurvey)
    switch (this.props.actionTaken) {
      case 'edit':
        this.setState({
          surveyQuestion: {
            question_uuid: this.props.activeSurvey.question_uuid,
            question_label: this.props.activeSurvey.question_label,
            type: this.props.activeSurvey.type,
            place_holder_text: this.props.activeSurvey.place_holder_text,
            notification_label: this.props.activeSurvey.notification_label,
            translation_key: this.props.activeSurvey.translation_key,
            question_label_markdown: this.props.activeSurvey.question_label_markdown,
          },
          surveyQestionOptions: this.props.activeSurvey.chocies && this.props.activeSurvey.chocies.length > 0 ? this.props.activeSurvey.choices : [],
          action: 'edit',
        });
        break;
    
      case 'copy':
        this.setState({
          surveyQuestion: {
            question_uuid: `${this.props.activeSurvey.question_uuid}(copy)`,
            question_label: this.props.activeSurvey.question_label,
            type: this.props.activeSurvey.type,
            place_holder_text: this.props.activeSurvey.place_holder_text,
            notification_label: this.props.activeSurvey.notification_label,
            translation_key: this.props.activeSurvey.translation_key,
            question_label_markdown: this.props.activeSurvey.question_label_markdown,
          },
          surveyQestionOptions: this.props.activeSurvey.chocies && this.props.activeSurvey.chocies.length > 0 ? this.props.activeSurvey.choices : [],
          action: 'copy',
        });
        break;
      
      default:
        this.setState({
          surveyQuestion:
            {
              question_uuid: '',
              question_label: '',
              type: '',
              place_holder_text: '',
              notification_label: '',
              translation_key: '',
              question_label_markdown: '',
            },
            action: 'add',
          });
        break;
    }
  }

  handleDragStart = (e: React.DragEvent, index: number) => {
    this.setState({ dragIndex: index });
  };

  // Handle drag over, allow drop
  handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  // Handle drop, move the dragged item to the new position
  handleDrop = (e: React.DragEvent, targetIndex: number) => {
    let { dragIndex, surveyQestionOptions } = this.state;
    if (dragIndex === targetIndex) return;

    // Clone the survey options array to avoid direct mutation
    let updatedOptions = [...surveyQestionOptions];
    // Remove the item from the dragged index
    if (dragIndex !== undefined) {
      const draggedItem = updatedOptions.splice(dragIndex, 1)[0];
    
      // Insert the item at the targetIndex
      updatedOptions.splice(targetIndex, 0, draggedItem);
    }
    // Update state with the new order
    this.setState({ surveyQestionOptions: updatedOptions, dragIndex: undefined });


  };
  handleOptionChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { name, value } = event.target;
    const updatedOptions = [...this.state.surveyQestionOptions];
  
    let previousOption = updatedOptions[index];
    previousOption[name] = value;

    this.setState({
      surveyQestionOptions: updatedOptions,
    });

  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    this.setState({
      surveyQuestion: {
        ...this.state.surveyQuestion,
        [name]: value,
      },
    });
  }

  handleDropdownChange(option: any) {
    const multipleChoice = ['select_all_that_apply', 'select_one'];
    const newOption: surveyQuestionOption = {
      value: ' ',
      label: ' ',
      order: 0,
    };
  
    this.setState((prevState) => ({
      surveyQuestion: {
        ...prevState.surveyQuestion,
        type: option.value,
      },
      surveyQestionOptions: multipleChoice.includes(option.value)
        ? [newOption]
        : prevState.surveyQestionOptions,
    }));
  }
  addQuestionOption() {
    let newQuestion = {
      value: ' ',
      label: ' ',
      order: this.state.surveyQestionOptions.length,
    };
    this.setState((prevState) => ({
      surveyQestionOptions: [...prevState.surveyQestionOptions, newQuestion],
    }));
  }

  deleteOption(index: number) {
  let questionOptions = [...this.state.surveyQestionOptions];
  questionOptions.splice(index, 1);
  this.setState({ surveyQestionOptions: questionOptions });
  }

  render() {
    return ReactDOM.createPortal(
      <div
        onClick={() => this.props.onClose()}
        style={{ zIndex: 9999, display: 'block' }}
        className={'modal fade ' + 'in'}
        role="dialog"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="modal-question-builder"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {this.props.modalTitle}
              </h4>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-4" style={{ marginTop: '5px' }}>
                  <span className="m-b-15-purple">Question UUID</span>
                  <input
                    type="text"
                    className="form-control"
                    name="question_uuid"
                    value={this.state.surveyQuestion.question_uuid}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-6" style={{ marginTop: '5px' }}>
                  <span className="m-b-15-purple">Question Label</span>
                  <input
                    type="text"
                    className="form-control"
                    name="question_label"
                    value={this.state.surveyQuestion.question_label}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4" style={{ marginTop: '5px' }}>
                  <span className="m-b-15-purple">Type</span>
                  <Dropdown
                    options={[
                      { value: ' ', label: ' ' },
                      ...surveyQuestionTypes
                    ]}
                    onChange={this.handleDropdownChange}
                    value={this.state.surveyQuestion.type}
                    placeholder="&nbsp;"
                  />
                </div>

                <div className="col-md-4" style={{ marginTop: '5px' }}>
                  <span className="m-b-15-purple">PlaceHolder Text</span>
                  <input
                    type="text"
                    className="form-control"
                    name="place_holder_text"
                    value={this.state.surveyQuestion.place_holder_text}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-4" style={{ marginTop: '5px' }}>
                  <span className="m-b-15-purple">Notification Label</span>
                  <input
                    type="text"
                    className="form-control"
                    name="notification_label"
                    value={this.state.surveyQuestion.notification_label}
                    onChange={this.handleChange}
                  />
                </div>
              </div>


              <div className="row">
                <div className="col-md-5" style={{ marginTop: '5px', paddingBottom: '2px' }}>
                  <span className="m-b-15-purple">Translation Key</span>
                  <input
                    type="text"
                    className="form-control"
                    name="translation_key"
                    value={this.state.surveyQuestion.translation_key}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              {this.state.surveyQestionOptions && this.state.surveyQestionOptions.length > 0 && (
                  <>
                    {/* Render the titles only once above the first row */}
                    <div className="row">
                      <div className="col-md-3" style={{ marginTop: '2px', paddingBottom: '3px' }}>
                        <span className="m-b-15-purple">Choice</span>
                      </div>
                      <div className="col-md-3" style={{ marginTop: '2px', paddingBottom: '3px' }}>
                        <span className="m-b-15-purple">Value</span>
                      </div>
                      <div className="col-md-3" style={{ marginTop: '2px', paddingBottom: '3px' }}>
                        <span className="m-b-15-purple">Translation Key</span>
                      </div>
                    </div>
                              
                    {/* Render the inputs for each option */}
                    {this.state.surveyQestionOptions.map((option, index) => (
                      <div 
                        key={option.order} 
                        className="row" 
                        onDragOver={(e) => this.handleDragOver(e)} // Allow dragging over
                        onDrop={(e) => this.handleDrop(e, index)} // Handle the drop>
                        >
                        <div className="col-md-3" style={{ marginTop: '2px', paddingBottom: '3px' }}>
                          <input
                            type="text"
                            className="form-control"
                            name={`choice`} // Unique name for each input
                            value={option.choice}
                            onChange={(e)=>this.handleOptionChange(e, index)}
                          />
                        </div>
                        <div className="col-md-3" style={{ marginTop: '2px', paddingBottom: '3px' }}>
                          <input
                            type="text"
                            className="form-control"
                            name={`value`} // Unique name for each input
                            value={option.value}
                            onChange={(e)=>this.handleOptionChange(e, index)}
                          />
                        </div>
                        <div className="col-md-3" style={{ marginTop: '2px', paddingBottom: '3px' }}>
                          <input
                            type="text"
                            className="form-control"
                            name={`translation_key`} // Unique name for each input
                            value={option.translation_key}
                            onChange={(e)=>this.handleOptionChange(e, index)}
                          />
                        </div>
                        <i className="fa fa-trash" onClick={()=>this.deleteOption(index)}> </i>
                        {/* Drag handle */}
                        <a                  
                          href="#"
                          draggable="true"
                          className="pull-left min-width--inherit"
                          onDragStart={(e) => this.handleDragStart(e, index)} >
                          <svg className="list-icon" xmlns="http://www.w3.org/2000/svg" height="1em" width="1em" viewBox="0 0 180 600">
                            <path d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"/>
                          </svg>
                        </a>

                      </div>
                    ))}
                   
                   
                   {/* Add Option Button */}
                    <button
                      type="button"
                      className="btn btn-light-outline no-margin-right"
                      data-dismiss="modal"
                      onClick={this.addQuestionOption}
                    >
                      Add Option
                    </button>
                  </>
                )}

              <div className="modal-footer" style={{ height: '35px' }}>
                <button
                  onClick={() => this.props.onClose()}
                  type="button"
                  className="btn btn-light-outline no-margin-right"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  onClick={() => this.props.onSave(this.props.actionTaken, this.state.surveyQuestion)}
                  type="button"
                  className="btn btn-dark"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default EditAddQuestionModal;
